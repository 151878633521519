<template>
    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row" v-if="!dateOnly">
            <div class="col col-6 form-group form-required" :class="{ 'has-error' : v$.item.regionIDs.$error }">
                <label for="tb_regions">Region(s)</label>
                <dx-tag-box
                    automation_id="tb_regions"
                    class="form-control"
                    :data-source="regions"
                    display-expr="displayName"
                    value-expr="regionID"
                    :show-selection-controls="true"
                    :max-displayed-tags="1"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    :search-enabled="false"
                    v-model="v$.item.regionIDs.$model"
                />
                <rq-validation-feedback>Region(s) is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group form-required" :class="{ 'has-error' : v$.item.state.$error }">
                <label for="drp_state">State</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_state', id: 'drp_state' }"
                    :items="states"
                    value-expr="id"
                    display-expr="id"
                    :search-enabled="true"
                    v-model="v$.item.state.$model"
                />
                <rq-validation-feedback>State is required</rq-validation-feedback>
            </div>
        </div>
        <div class="row" >
            <div v-if="!dateOnly" class="col col-6 form-group form-required" :class="{ 'has-error' : v$.item.effectiveDateBasisID.$error }">
                <label for="drp_effective_date_basis">Effective Date Basis</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_effective_date_basis', id: 'drp_effective_date_basis' }"
                    :items="effectiveDateBasedOnOptions"
                    value-expr="id"
                    display-expr="name"
                    :search-enabled="true"
                    v-model="v$.item.effectiveDateBasisID.$model"
                />
                <rq-validation-feedback>Effective Date Basis is required</rq-validation-feedback>
            </div>
            <div class="col col-6 form-group form-required" :class="{ 'has-error': v$.item.effectiveDate.$error || (v$.item.effectiveDate.$anyDirty && v$.item.uniqueEffectiveDate.$invalid) }">
                <label for="dtp_effective_date">Effective Date</label>
                <rqdx-date-box
                    id="dtp_effective_date"
                    :show-clear-button="false"
                    v-model="v$.item.effectiveDate.$model"
                />
                <rq-validation-feedback
                    :messages="{
                        'Effective Date is required.': v$.item.effectiveDate.$error,
                        'An Effective Date already exists with that Rate.': v$.item.uniqueEffectiveDate.$invalid
                    }"
                />
            </div>
        </div>
        <div class="row" >
            <div class="col col-12 form-group" v-if="showCode">
                <label for="txt_code">Linked Premium</label>
                <premium-picker
                    automation_id="drp_linked_premium"
                    dialog-title="Premium Rates"
                    v-model="v$.item.linkedPremiumRateID.$model"
                />
            </div>
        </div>

        <div class="row" >
            <div class="col col-12 form-group form-required" v-if="showCode" :class="{'has-error' : v$.item.code.$error}">
                <label for="txt_code">Premium Code</label>
                <input automation_id="txt_code" v-model="v$.item.code.$model" type="text" class="form-control" placeholder="Premium Code" maxlength="20">
                <rq-validation-feedback>Premium Code is required</rq-validation-feedback>
            </div>
        </div>
        <div class="row" v-if="showCode">
            <div class="col col-12 form-group">
                <label for="txt_description">Description</label>
                <input automation_id="txt_description" v-model="item.extendedDescription" type="text" class="form-control" placeholder="Description" maxlength="50">
            </div>
        </div>
        <div class="row" v-if="showCopyData">
            <div class="col col-12 form-group">
                <b-form-checkbox
                    automation_id="chk_copy_data"
                    id="chk_copy_data"
                    v-model="item.copyData"
                    >Copy Existing Data</b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { required } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EffectiveDateBasedOn, RateEngineFileTypeName, RateCreateType }  from "../../enums";
    import PremiumPicker from './PremiumPicker';
    import { DateTime } from "luxon";

    export default {
        name: "PremiumFormAdd",
        components: {
            PremiumPicker
        },
        props: {
            item: { type: Object, default: () => ({}) },
            effectiveRates: { type: Array, default: () => [] }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                showBanner: false
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            dateOnly() {
                return this.item.rateCreateType === RateCreateType.CopyRate || this.item.rateCreateType === RateCreateType.NewEffectiveDate;
            },
            showCode() {
                return this.item.rateCreateType === RateCreateType.NewRate || this.item.rateCreateType === RateCreateType.CopyRate || this.item.rateCreateType === RateCreateType.LinkRate;
            },
            showCopyData() {
                return this.item.rateCreateType === RateCreateType.LinkRate || this.item.rateCreateType === RateCreateType.NewEffectiveDate;
            },
            regions() { return this.lookupHelpers.getRegions(); },
            states() { return this.lookupHelpers.getStates(); },
            effectiveDateBasedOnOptions() { return EffectiveDateBasedOn.lookupItems; },
            rateEngineFileTypeNames() { return RateEngineFileTypeName.lookupItems; }
        },
        validations: {
            item: {
                regionIDs: { required },
                state: { required },
                effectiveDateBasisID: { required },
                effectiveDate: { required },
                code: { required },
                linkedPremiumRateID: { },
                uniqueEffectiveDate(val) {
                    return this.isUniqueEffectiveDate(val.effectiveDate);
                }
            }
        },
        created() {
            if(this.isUniqueEffectiveDate(this.item.effectiveDate)) return;
            this.item.effectiveDate = null;
        },
        methods: {
            isUniqueEffectiveDate(dateVal) {
                var date = DateTime.fromISO(dateVal).toFormat("MM/dd/yyyy");
                return !_.some(this.effectiveRates, r => r.name === date);
            },
            isValid() {
                this.v$.$touch();
                return !this.v$.$error;
            }
        }
    }
</script>