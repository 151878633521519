<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless>
             <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            v-rq-tooltip.hover.top title="Discard changes and reload data"
                            @click="onCancel"
                            :disabled="readOnly">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave"
                            :disabled="readOnly">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
            <div class="row mb-1">
                <div class="col-auto ms-auto">
                    <rq-section-expand-collapse-all />
                </div>
            </div>
            <rq-section-card title="File Management" collapsible>
                <div class="row">
                    <div class="col col-12 checkbox-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_suggest_next_file_number"
                            id="suggest_next_file_number"
                            v-model="systemDefaults.suggestNextFileNumber"
                            :disabled="readOnly">Suggest Next File Number</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_enable_file_in_use_warning"
                            id="enable_file_in_use_warning"
                            v-model="systemDefaults.limitMultipleUserFileAccess"
                            :disabled="readOnly">Enable File in Use Warning</b-form-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                    <label for="days_in_work_week">Days in Work Week</label>
                        <rq-input-number
                            id="txt_days_in_work_week"
                            automation_id="txt_days_in_work_week"
                            defaultValue="0"
                            decimals="0"
                            cssClass="form-control"
                            maxValue=7
                            v-model="systemDefaults.daysInWorkWeek"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="system_default_duplicate_file_checking">System Default Duplicate File Checking</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_system_default_duplicate_file_checking', id: 'drp_system_default_duplicate_file_checking' }"
                            :items="systemDefaultDuplicateFileChecking"
                            value-expr="id"
                            display-expr="name"
                            v-model="systemDefaults.duplicateOrderSearchSetupID"
                            :search-enabled="true"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-8 col-lg-4 form-group">
                        <label for="dtp_default_start_time">Default Start Time</label>
                        <rqdx-date-box
                            id="dtp_default_start_time"
                            v-model="defaultStartTimeValue"
                            type="time"
                            auto-open
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-8 col-lg-4 form-group">
                        <label for="dtp_default_end_time">Default End Time</label>
                        <rqdx-date-box
                            id="dtp_default_end_time"
                            v-model="defaultEndTimeValue"
                            type="time"
                            auto-open
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-8 col-lg-4 form-group">
                        <label for="drp_Workflow_Time_Zone">Workflow Time Zone</label>
                        <rq-action-form-group
                            required
                            :hasError="v$.systemDefaults.workflowTimeZone.$error"
                        >
                            <template #default>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_Workflow_Time_Zone' }"
                                    :items="wftimezones"
                                    display-expr="display"
                                    value-expr="zoneId"
                                    :search-expr="['display']"
                                    :disabled="readOnly"
                                    v-model="v$.systemDefaults.workflowTimeZone.$model">
                                </dx-select-box>
                            </template>
                        </rq-action-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-6 form-group">
                        <label for="dtp_default_start_time">Portfolio Manager Access in Months</label>
                        <rq-input-number
                            id="txt_portfolio_manager_access_in_months"
                            automation_id="txt_portfolio_manager_access_in_months"
                            defaultValue="24"
                            decimals="0"
                            cssClass="form-control"
                            minValue="1"
                            maxValue="48"
                            v-model="systemDefaults.portfolioManagerAccessInMonths"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </rq-section-card>
            <rq-section-card title="Vesting Information" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 checkbox-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_owner_insured"
                            id="show_on_owner_insured"
                            v-model="systemDefaults.showOnOwnerInsured"
                            :disabled="readOnly">Show on Owner Insured</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_show_on_mortgage_borrower"
                            id="show_on_mortgage_borrower"
                            v-model="systemDefaults.showOnMortgageBorrower"
                            :disabled="readOnly">Show on Mortgage Borrower</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_show_on_current_titles_holders_buyer"
                            id="show_on_current_titles_holders_buyer"
                            v-model="systemDefaults.showOnCurrentTitleHoldersBuyer"
                            :disabled="readOnly">Show on Current Title Holders/Buyer</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-lg-6 checkbox-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_show_on_current_titles_holders_seller"
                            id="show_on_current_titles_holders_seller"
                            v-model="systemDefaults.showOnCurrentTitleHoldersSeller"
                            :disabled="readOnly">Show on Current Title Holders/Seller</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_show_on_title_vested_in_buyer"
                            id="show_on_title_vested_in_buyer"
                            v-model="systemDefaults.showOnTitleVestedInBuyer"
                            :disabled="readOnly">Show on Title Vested In/Buyer</b-form-checkbox>
                    </div>
                </div>

            </rq-section-card>
            <rq-section-card title="Miscellaneous" collapsible>
                <div class="row">
                    <div class="col col-12 col-lg-6 checkbox-group label-lg">
                        <b-form-checkbox
                            automation_id="chk_default_sellers_for_1099"
                            id="default_sellers_for_1099"
                            v-model="systemDefaults.defaultSellersForNo1099Reporting"
                            :disabled="readOnly">Default Sellers for 1099</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_sellers_realtor_as_listing_agent"
                            id="sellers_realtor_as_listing_agent"
                            v-model="systemDefaults.useSellerRealtorAsListingAgent"
                            :disabled="readOnly">Seller's Real Estate Agent as Listing Agent</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_buyers_realtor_as_selling_agent"
                            id="buyers_realtor_as_selling_agent"
                            v-model="systemDefaults.useBuyerRealtorAsSellingAgent"
                            :disabled="readOnly">Buyer's Real Estate Agent as Selling Agent</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_user_preferred_vendor_selection"
                            id="user_preferred_vendor_selection"
                            v-model="systemDefaults.usePrefVendor"
                            :disabled="readOnly">Preferred Vendor Selection</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_apportion_Tax_proration_credit_to_multiple_sellers"
                            id="apportion_Tax_proration_credit_to_multiple_sellers"
                            v-model="systemDefaults.apportionTaxProrationCreditMultipleSellers"
                            :disabled="readOnly">Apportion Tax Proration Credit to Multiple Sellers according to 1099 proceeds distribution</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_use_white_text_tagging"
                            id="use_white_text_tagging"
                            v-model="systemDefaults.useWhiteTextTagging"
                            :disabled="readOnly">Use White Text Tagging</b-form-checkbox>
                        <b-form-checkbox
                            automation_id="chk_enable_batch_invoice_note"
                            id="enable_batch_invoice_note"
                            v-model="systemDefaults.enableBatchInvoiceNote"
                            :disabled="readOnly">Enable Batch Invoice Note</b-form-checkbox>
                    </div>
                    <div class="col col-12 col-lg-6">
                        <div class="form-group">
                            <label for="default_tax_rate_for_invoices">Default tax rate % for invoices</label>
                            <div class="input-group">
                                <rq-input-number
                                    v-model="systemDefaults.invoiceTaxRate"
                                    defaultValue="0"
                                    decimals="3"
                                    minValue="0"
                                    maxValue="100"
                                    cssClass="form-control"
                                    automation_id="txt_default_tax_rate_for_invoices"
                                    id="txt_default_tax_rate_for_invoices"
                                    :disabled="readOnly"
                                />
                                <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="refresh_interval_for_workflow_dashboard">Refresh Interval for Workflow Dashboard in Minutes</label>
                            <rq-input-number
                                id="txt_refresh_interval_for_workflow_dashboard"
                                automation_id="txt_refresh_interval_for_workflow_dashboard"
                                defaultValue="0"
                                decimals="0"
                                cssClass="form-control"
                                minValue="0"
                                maxValue="500"
                                v-model="systemDefaults.workflowDashboardRefreshInterval"
                                :disabled="readOnly"
                            />
                        </div>
                        <div class="form-group">
                            <label for="refresh_interval_for_inbound_order_alarms">Refresh Interval for Inbound File Alarms in Minutes</label>
                            <rq-input-number
                                id="txt_refresh_interval_for_inbound_order_alarms"
                                automation_id="txt_refresh_interval_for_inbound_order_alarms"
                                defaultValue="0"
                                decimals="0"
                                cssClass="form-control"
                                v-model="systemDefaults.internetAlertsRefreshInterval"
                                :disabled="readOnly"
                            />
                        </div>
                        <!-- RQO-16456 - comment out as we are not currently using this input -->
                        <!-- <div class="form-group">
                            <label for="order_heartbeat_interval">Refresh Interval for File Heartbeat in Seconds</label>
                            <rq-input-number
                                id="txt_order_heartbeat_interval"
                                automation_id="txt_order_heartbeat_interval"
                                defaultValue="0"
                                decimals="0"
                                cssClass="form-control"
                                v-model="systemDefaults.heartbeatFrequency"
                                :disabled="readOnly"
                            />
                        </div> -->
                        <div :class="{ 'form-group form-required':true, 'has-error':v$.systemDefaults.fileInUseTimeoutInMinutes.$error }">
                            <label for="txt_file_in_use_timeout">File In Use Timeout in Minutes</label>
                            <rq-input-number
                                id="txt_file_in_use_timeout"
                                automation_id="txt_file_in_use_timeout"
                                defaultValue="15"
                                decimals="0"
                                cssClass="form-control"
                                v-model="v$.systemDefaults.fileInUseTimeoutInMinutes.$model"
                                :disabled="readOnly"
                            />
                            <rq-validation-feedback
                                :messages="{ 'Value is required': !v$.systemDefaults.fileInUseTimeoutInMinutes.required.$invalid,
                                             'Value must be at least 10 minutes': !v$.systemDefaults.fileInUseTimeoutInMinutes.minValue,
                                             'Value must be less than 30 minutes': !v$.systemDefaults.fileInUseTimeoutInMinutes.maxValue,}"
                            />
                        </div>
                    </div>
                </div>
            </rq-section-card>
        </rq-page-section>
    </div>
</template>

<script>
    import { GlobalEventManager } from "@/app.events";
    import { mapState, mapGetters } from "vuex";
    import SystemDefaultsFormMixin from "../../shared/SystemDefaultsFormMixin";
    import { required, minValue, maxValue } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { DateTime } from "luxon";

    export default {
        name: "FileSettingForm",
        mixins: [SystemDefaultsFormMixin],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                wftimezones:[
                            { zoneId: "Pacific/Honolulu", display: "UTC-10 - US/Hawaii" },
                            { zoneId: "America/Anchorage", display: "UTC-9 - US/Alaska" },
                            { zoneId: "America/Los_Angeles", display: "UTC-8 - US/Pacific" },
                            { zoneId: "America/Phoenix", display: "UTC-7 - US/Arizona" },
                            { zoneId: "America/Denver", display: "UTC-7 - US/Mountain" },
                            { zoneId: "America/Chicago", display: "UTC-6 - US/Central" },
                            { zoneId: "America/New_York", display: "UTC-5 - US/Eastern" }
                            ],
            }
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            systemDefaultDuplicateFileChecking() { return this.lookupHelpers.getLookupItems(this.lookupItems.DUPLICATE_ORDER_SEARCH_SETUP, this.globalRegionId); },
            
            defaultStartTimeValue: {
                get () { return this.systemDefaults.defaultStartTime && DateTime.fromFormat(this.systemDefaults.defaultStartTime, "HH:mm:ss").toISO(); },
                set (val) { this.systemDefaults.defaultStartTime = _.parseDateString(val, null, "HH:mm:ss"); }
            },
            
            defaultEndTimeValue: {
                get () { return this.systemDefaults.defaultEndTime && DateTime.fromFormat(this.systemDefaults.defaultEndTime, "HH:mm:ss").toISO(); },
                set (val) { this.systemDefaults.defaultEndTime = _.parseDateString(val, null, "HH:mm:ss"); }
            },
        },
        watch: {
            "systemDefaults.daysInWorkWeek"(newValue, oldValue) {
                if(newValue === oldValue || newValue >= 0) return;
                this.systemDefaults.daysInWorkWeek = 0;
            }
        },
        validations: () => ({
            systemDefaults: {
                fileInUseTimeoutInMinutes: {
                    required,
                    minValue: minValue(10),
                    maxValue: maxValue(30)
                },
                workflowTimeZone: {
                        required
                    },
            }
        })
    }
</script>
