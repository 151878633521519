<template>
    <div class="content-wrapper config-workflow-depts" v-if="hasProcessTemplate">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showValidationBanner && (v$.$error || fileNumberInvalid)"
        />
        <rq-banner
            variant="warn"
            message="It may be a few minutes before you see the updates in Workflow on existing files using this Process Template"
            icon="fas fa-exclamation-triangle"
            :visible="showTaskEditMessageBanner"
            @dismiss="showTaskEditMessageBanner=false"
            dismissable
        />
        <rq-banner
            variant="warn"
            message="Depending on the number of files using this template, it may be a few minutes before you see the updates in Generate Documents."
            icon="fas fa-exclamation-triangle"
            :visible="showTemplateEditMessageBanner"
            @dismiss="showTemplateEditMessageBanner=false"
            dismissable
        />
        <rq-page-section :title="editTitle" headerSize="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav config-actions ms-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_cancel"
                            variant="action"
                            @click="onCancel">Cancel
                        </b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_save"
                            variant="action"
                            @click="onSave">Save
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <b-btn automation_id="lnk_back" variant="link" :to="{ name:'cfg:workflow:processTemplates'}">View Process Templates</b-btn>
            </template>
        </rq-page-section>
        <rq-tabs :tabs="tabItems" v-model="tabIndex">
            <template #general>
                <rq-page-section title="General" headerSize="md" borderless>
                    <fieldset :disabled="readOnly">
                        <div class="row">
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.currentData.workflowProcessTemplate.processTemplateName.$error } ">
                                <label for="txt_process_template_name">Process Template Name</label>
                                <input automation_id="txt_process_template_name" v-model="v$.currentData.workflowProcessTemplate.processTemplateName.$model" type="text" class="form-control" placeholder="Process Template Name" maxlength="50">
                                <rq-validation-feedback
                                    :messages="{
                                        'Process Template Name is required.':v$.currentData.workflowProcessTemplate.processTemplateName.required.$invalid,
                                        'A Process Template already exists with that name.': v$.currentData.workflowProcessTemplate.processTemplateName.isNameUnique.$invalid
                                    }"
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required" :class="{ 'has-error': v$.currentData.workflowProcessTemplate.regionID.$error }">
                                <label for="drp_region">Region</label>
                                <dx-select-box
                                    :input-attr="{ automation_id: 'drp_region', id: 'drp_region' }"
                                    :items="regionsLookup"
                                    value-expr="regionID"
                                    display-expr="displayName"
                                    v-model="v$.currentData.workflowProcessTemplate.regionID.$model"
                                    @change="onRegionChange"
                                />
                                <rq-validation-feedback
                                    :messages="{
                                        'Region is required.':v$.currentData.workflowProcessTemplate.regionID.required.$invalid
                                    }"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-12 col-md-6 col-lg-3">
                                <file-number-input
                                    automation_id="txt_gfno"
                                    label="Data File #"
                                    status-filter="4"
                                    :visible-columns="['gfNo', 'region', 'branch', 'status', 'buyer', 'seller', 'lender', 'underwriter', 'propertyAddress']"
                                    v-model:has-error="fileNumberInvalid"
                                    v-model="currentData.workflowProcessTemplate.gfNo"
                                    v-model:order-id="currentData.workflowProcessTemplate.ordersID"
                                    :disabled="hasDefaultSSType && !hasDataFile"
                                    @file-selected="onFileSelected"
                                    show-clear-button
                                    show-search-button
                                />
                            </div>
                            <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                                <label for="default_settlement_statement_type">Default Settlement Statement Type</label>
                                <span v-rq-tooltip :title="ssTypeTooltip">
                                    <dx-select-box
                                        :input-attr="{ id: 'drp_default_settlement_statement_type', automation_id: 'drp_default_settlement_statement_type' }"
                                        value-expr="id"
                                        display-expr="name"
                                        :items="settlementTypeItems"
                                        :search-enabled="false"
                                        :show-clear-button="true"
                                        :disabled="readOnly || hasDataFile"
                                        @value-changed="onChangeSettlementType"
                                        v-model="currentData.workflowProcessTemplate.defaultSSType"
                                    />
                                </span>
                            </div>
                            <div v-if="isCdf" class="col-1 form-group">
                                <label>Include ALTA</label>
                                <div>
                                    <rqSwitch
                                        automation_id="chk_include_alta"
                                        type="text"
                                        variant="primary"
                                        on="Yes"
                                        off="No"
                                        size="lg"
                                        :disabled="readOnly || hasDataFile"
                                        v-model="currentData.workflowProcessTemplate.includeAlta"
                                    />
                                </div>
                            </div>
                            <div v-if="hasDefaultSSType" class="col-2 form-group">
                                <label>{{withoutSellerLabel}}</label>
                                <div>
                                    <rqSwitch
                                        automation_id="chk_without_seller"
                                        type="text"
                                        variant="primary"
                                        on="Yes"
                                        off="No"
                                        size="lg"
                                        :disabled="readOnly || hasDataFile"
                                        v-model="currentData.workflowProcessTemplate.isWithOutSeller"
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </rq-page-section>
            </template>
            <template #tasks>
                <rq-page-section title="Tasks" headerSize="md" borderless>
                    <grid-selector
                        id="taskGridSelector"
                        ref="taskGridSelector"
                        :items="workflowTasks"
                        :item-key="taskItemKey"
                        item-name="Tasks"
                        sort-description-field="taskName"
                        sort-field="sequence"
                        :grid-configs="taskGridConfigs"
                        :read-only="readOnly"
                        v-model="currentData.workflowTaskIDs"
                        @reorder="onSelectedTasksReorder"
                        selectAllPages
                    />
                </rq-page-section>
            </template>
            <template #documents>
                <rq-page-section title="Documents" headerSize="md" borderless>
                    <grid-selector
                        id="documentGridSelector"
                        ref="documentGridSelector"
                        :items="documentTemplates"
                        :item-key="docItemKey"
                        item-name="Documents"
                        :grid-configs="docsGridConfigs"
                        :read-only="readOnly"
                        v-model="currentData.documentTemplateIDs"
                        hide-reorder
                        selectAllPages
                    />
                </rq-page-section>
            </template>
            <template #packages>
                <rq-page-section title="Packages" headerSize="md" borderless>
                    <grid-selector
                        id="packagesGridSelector"
                        :items="documentPackages"
                        :item-key="packageItemKey"
                        item-name="Packages"
                        :grid-configs="pkgGridConfigs"
                        :read-only="readOnly"
                        v-model="currentData.documentPackageIDs"
                        hide-reorder
                        selectAllPages
                    />
                </rq-page-section>
            </template>
            <template #summary>
                <rq-page-section title="Summary" headerSize="md" borderless>
                    <div class="row">
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="summary_data_file" class="text-blue mb-2">Data File #</label>
                            <div id="summary_data_file">{{currentData.workflowProcessTemplate.gfNo}}</div>
                        </div>
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="summary_region" class="text-blue mb-2">Region</label>
                            <div id="summary_region">{{currentData.workflowProcessTemplate.regionDisplay}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="summary_tasks" class="text-blue mb-2">Tasks</label>
                            <div class="d-flex flex-column summary_items" id="summary_tasks">
                                <div v-for="item in selectedWorkflowTasks" v-bind:key="item.workflowTaskID">{{item.taskName}}</div>
                            </div>
                        </div>
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="summary_documents" class="text-blue mb-2">Documents</label>
                            <div class="d-flex flex-column summary_items" id="summary_documents">
                                <div v-for="item in selectedDocuments" v-bind:key="item.documentTemplateID">{{item.description}}</div>
                            </div>
                        </div>
                        <div class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
                            <label for="summary_packages" class="text-blue mb-2">Packages</label>
                            <div class="d-flex flex-column summary_items" id="summary_packages">
                                <div v-for="item in selectedPackages" v-bind:key="item.documentPackageID">{{item.description}}</div>
                            </div>
                        </div>
                    </div>
                </rq-page-section>
            </template>
            <rq-back-to-top-button />
        </rq-tabs>
    </div>
</template>

<script>
    import { computed, ref } from "vue";
    import { useRoute } from "vue-router";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "validators";
    import { mapGetters, mapState } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import GridSelector from "../../shared/GridSelector";
    import { DocumentTemplateShortDto }  from "../../ssAndCalcs/models";
    import { WorkflowProcessTemplateDataDto, DocumentPackageShortDto, WorkflowTaskShortDto }  from "../models";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import { SETTLEMENT_TYPE } from '@settlement/models';
    import { useRqTabAlerts } from "@/shared/composables/useRqTabAlerts";
    import { DateTime } from "luxon";

    const TabIndex = {
        General: 0,
        Tasks: 1,
        Documents: 2,
        Packages: 3,
        Summary: 4
    }

    function isNameUnique(value){
        return !this.isDuplicate;
    }

    export default {
        name: 'WorkflowProcessTemplateForm',
        components: { FileNumberInput, GridSelector },
        props: ["workflowProcessTemplateID", "items"],
        setup(props) {
            const route = useRoute();

            const constants = {
                itemKey: "workflowProcessTemplateID",
                itemTypeName: _.get(route, "meta.itemTypeName"),
                itemTypeDescription: _.get(route, "meta.itemTypeDescription"),
                docItemKey: "documentTemplateID",
                taskItemKey: "workflowTaskID",
                packageItemKey: "documentPackageID"
            };

            const originalData = ref({});
            const currentData = ref({});

            const workflowTasks = ref([]);
            const documentTemplates = ref([]);
            const documentPackages = ref([]);

            const v$ = useVuelidate();
            const alertMapping = { generalTabAlert: { type: "v$" } };
            const { generalTabAlert } = useRqTabAlerts({ v$, alertMapping });


            const isCdf = computed(() => currentData.value?.workflowProcessTemplate?.defaultSSType === SETTLEMENT_TYPE.CDF);
            const withoutSellerLabel = computed(() => isCdf.value ? "Without Seller" : "HUD-1A");
            const editTitle = computed(() => `Edit ${constants.itemTypeName}: ${currentData.value?.workflowProcessTemplate?.processTemplateName || "(Blank)"}`);
            const hasProcessTemplate = computed(() => !_.isEmpty(currentData.value?.workflowProcessTemplate));
            const isNew = computed(() => props.workflowProcessTemplateID === 0);
            const hasDataFile = computed(() => !_.isEmpty(currentData.value?.workflowProcessTemplate?.gfNo));
            const hasDefaultSSType = computed(() => !_.isNil(currentData.value?.workflowProcessTemplate?.defaultSSType));

            const ssTypeTooltip = computed(() => hasDataFile.value
                ? _.isNil(currentData.value.workflowProcessTemplate.defaultSSType)
                    ? "Process Template contains a data file without a Settlement Statement,<br /> to change the default the Data File must be updated or removed."
                    : "Process Template contains a default Settlement Statement,<br /> to change the default the Data File must be removed."
                : "");

            const mapData = obj => {
                currentData.value = new WorkflowProcessTemplateDataDto(obj);
                originalData.value = new WorkflowProcessTemplateDataDto(obj);
                workflowTasks.value = _.map(obj.workflowTasks, i => new WorkflowTaskShortDto(i));
                documentTemplates.value = _.map(obj.documentTemplates, i => new DocumentTemplateShortDto(i));
                documentPackages.value = _.map(obj.documentPackages, i => new DocumentPackageShortDto(i));
            };

            return {
                ...constants,
                v$,
                originalData,
                currentData,
                workflowTasks,
                documentTemplates,
                documentPackages,
                generalTabAlert,
                fileNumberInvalid: ref(false),
                tabIndex: ref(TabIndex.General),
                showValidationBanner: ref(false),
                showTaskEditMessageBanner: ref(false),
                showTemplateEditMessageBanner: ref(false),
                mapData,
                isCdf,
                withoutSellerLabel,
                editTitle,
                hasProcessTemplate,
                isNew,
                hasDataFile,
                hasDefaultSSType,
                ssTypeTooltip
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup",
                "currentSession"
            ]),
            tabItems() {
                const self = this;
                return [
                    { automation_id: "btn_tab_general", name: "general", title: "General", alertCount: this.generalTabAlert.alertCount },
                    { automation_id: "btn_tab_tasks", name: "tasks", title: "Tasks" },
                    { automation_id: "btn_tab_documents", name: "documents", title: "Documents" },
                    { automation_id: "btn_tab_packages", name: "packages", title: "Packages" },
                    { automation_id: "btn_tab_summary", name: "summary", title: "Summary"},
                ];
            },
            settlementTypeItems() { 
                return _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.SETTLEMENT_TYPES), i => i.id !== SETTLEMENT_TYPE.CSS); // RQO-33488 Trim out of CSS option for now
            },
            lastModified() {
                return _.isNil(this.currentData?.workflowProcessTemplate?.lastModified)
                    ? DateTime.now().toFormat('MM/dd/yyyy, hh:mm a')
                    : DateTime.fromISO(this.currentData?.workflowProcessTemplate?.lastModified).toFormat('MM/dd/yyyy, hh:mm a');
            },
            readOnly() { return !_.parseBool(_.get(this.currentData, "workflowProcessTemplate.canEdit", false), false); },
            selectedWorkflowTasks() {
                let includeIDs = this.currentData.workflowTaskIDs || [];
                return _.sortBy(_.filter(this.workflowTasks, dt => _.includes(includeIDs, _.parseNumber(_.get(dt, this.taskItemKey, 0)))), ["sequence"]);
            },
            selectedDocuments() {
                let includeIDs = this.currentData.documentTemplateIDs || [];
                return _.sortBy(_.filter(this.documentTemplates, dt => _.includes(includeIDs, _.parseNumber(_.get(dt, this.docItemKey, 0)))), ["description"]);
            },
            selectedPackages() {
                let includeIDs = this.currentData.documentPackageIDs || [];
                return _.sortBy(_.filter(this.documentPackages, dt => _.includes(includeIDs, _.parseNumber(_.get(dt, this.packageItemKey, 0)))), ["description"]);
            },
            showRequiredErrorBanner(){ return this.v$.$error; },
            workflowProcessTemplates() { return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_PROCESS_TEMPLATES); },
        },
        validations(){
            const self = this;
            
            const isNameUnique = () => {
                let item = self.currentData.workflowProcessTemplate;
                return !_.find(self.workflowProcessTemplates, (i) => {
                    return _.toLower(_.trim(item.processTemplateName)) === _.toLower(_.trim(i.name))
                        && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1)
                        && _.parseNumber(item.workflowProcessTemplateID, -1) != _.parseNumber(i.id, -1);
                })
            };
            return {
                currentData: {
                    workflowProcessTemplate: {
                        processTemplateName: { required, isNameUnique  },
                        regionID: { required }
                    }
                }
            };
        },
        watch:{
            "workflowProcessTemplate.gfNo" (newValue, oldValue) {
                if(newValue === oldValue || !_.isEmpty(newValue)) return;
                this.currentData.workflowProcessTemplate.defaultSSType = null;
            },
            tabIndex(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$nextTick(() => {
                    switch(newValue) {
                        case TabIndex.Tasks:
                            _.invoke(this, "$refs.taskGridSelector.updateDimensions");
                            break;
                        case TabIndex.Documents:
                            _.invoke(this, "$refs.documentGridSelector.updateDimensions");
                            break;
                        case TabIndex.Packages:
                            _.invoke(this, "$refs.packageGridSelector.updateDimensions");
                            break;
                    }
                });
            }
        },

        beforeRouteLeave(to, from, next) {            
            var self = this;            
            if (to.meta.label !== "Process Templates") {
                next(); // When navigating to process templates we do an auto-save as oppose to navigating else where
                return;
            }
            
            if (self.userAccess.full) {                
                let changes = self.getAuditChanges(self.originalData, self.currentData);
                self.save({ userInitiated: false })
                    .then(isValid => {                    
                        if(!isValid) {
                            next(false);
                            return;                    
                        }
                        if (self.currentData.workflowProcessTemplate.openOrderCount > 0 && !self.isOnlyChangeProcessTemplate(changes)) {
                            let onYes = () => { self.applyToExisting(); next(); };                            
                            let onNo = () => { GlobalEventManager.saveCompleted({ success: true }); next(); };
                            
                            self.$dialog.confirm("Confirm", `Do you want to apply the changes to the ${self.generateConfirmationMessage(changes)} to the ${self.currentData.workflowProcessTemplate.openOrderCount} existing file(s) using this process template?`, onYes, onNo, { cancelTitle: "No", okTitle: "Yes" });                     
                        }                    
                        else {next();} 
                    })
                    .catch(() => {
                        let failureMsg = "Unable to save changes.";
                        self.$toast.error(failureMsg);
                        GlobalEventManager.saveCompleted({success: false});
                    });      
            } 
            else {next();}
        },           

        created() {
            GlobalEventManager.onSave(this, this.onSave);
            this.initGridConfig();
            this.fetchData();
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },
        methods: {
            onFileSelected(e) {
                const self = this;
                self.currentData.workflowProcessTemplate.gfNo = e.data.gfNo;
                self.currentData.workflowProcessTemplate.defaultSSType = e.data.settlementStatementType;
                self.currentData.workflowProcessTemplate.includeAlta = e.data.includeAlta;
                self.currentData.workflowProcessTemplate.isWithOutSeller = _.isNil(e.data.isWithOutSeller) ? false : e.data.isWithOutSeller;
            },
            applyToExisting() {
                const self = this;
                let apiPromise = self.$api.WorkflowProcessTemplatesApi.applyToExisting(self.currentData.workflowProcessTemplate.workflowProcessTemplateID);
                return self.$rqBusy.wait(apiPromise)
                        .then(success => {
                            if (success) {
                                if(self.tabIndex === TabIndex.Tasks) {
                                    self.showTaskEditMessageBanner = true;
                                }
                                if(self.tabIndex === TabIndex.Documents || self.tabIndex == TabIndex.Packages) {
                                    self.showTemplateEditMessageBanner = true;
                                }
                                self.$toast.success(`Template Applied to ${this.currentData.workflowProcessTemplate.openOrderCount} Active File(s).`);
                            } else {
                                self.$toast.error(`Error Applying Template`);
                            }
                            GlobalEventManager.saveCompleted({ success: success });
                        })
                        .catch(error => {
                            self.$toast.error(`Error Applying Template`);
                            GlobalEventManager.saveCompleted({ success: false });
                        });
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.WorkflowProcessTemplatesApi.get(self.workflowProcessTemplateID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.mapData(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeName}.` });
                    });
            },
            initGridConfig(){
                const self = this;
                self.docsGridConfigs = {
                    columns: [
                        { dataField: "description", dataType: "string", sortIndex: 0, sortOrder: "asc", },
                        { dataField: "regionDisplay", dataType: "string", caption: "Region", allowSearch: false }
                    ]
                };
                self.taskGridConfigs = {
                    columns: [
                        { dataField: "taskName", dataType: "string" },
                        { dataField: "workflowDepartmentName", dataType: "string", caption: "Dept" },
                        { dataField: "sequence", dataType: "number", visible: false, showInColumnChooser: false, sortIndex: 0, sortOrder: "asc" }
                    ]
                };

                self.pkgGridConfigs = {
                    columns: [ { dataField: "description", dataType: "string" } ],
                };
            },
            onCancel(e) {
                const self = this;
                let changes = self.getAuditChanges(self.currentData, self.originalData);

                if (changes.length === 0) {
                    self.$toast.info("No changes detected");
                    return;
                }
                self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", () => {
                    self.currentData = new WorkflowProcessTemplateDataDto(_.cloneDeep(self.originalData));
                    self.v$.$reset();
                });
            },
            onSelectedTasksReorder(e) {
                this.currentData.workflowTaskIDs = _.clone(e);
                this.originalData.workflowTaskIDs = [];//do this to trigger a change;
                this.save(e);
            },
            onRegionChange(e) {
                this.save(e); //refresh to get new lookups
            },
            onChangeSettlementType(e) {
                const self = this;
                // When switching to HUD SS type, we reset includeAlta to false for CDF
                switch(e.value) {
                    case SETTLEMENT_TYPE.CDF:
                        break; // do nothing
                    case SETTLEMENT_TYPE.HUD_1974:
                    case SETTLEMENT_TYPE.HUD_2010:
                        self.currentData.workflowProcessTemplate.includeAlta = false;
                        break;
                    default:
                        self.currentData.workflowProcessTemplate.includeAlta = false;
                        break;
                }
            },
            onSave(e) {
                const self = this;
                e.userInitiated=true;

                self.$rqBusy.wait( self.save(e))
                    .then(resolved => {
                        GlobalEventManager.saveCompleted({success: resolved});
                    })
                    .catch(() => {
                            GlobalEventManager.saveCompleted({success: false});
                        }
                    );
            },
            onDismissTaskEditMessage() {
                this.showTaskEditMessageBanner = false;
            },
            onDismissTemplateEditMessage() {
                this.showTemplateEditMessageBanner = false;
            },
            save(args={}) {
                const self = this;

                self.v$.$touch();
                self.showValidationBanner = true;
                if (self.v$.$error) return Promise.resolve(false);
                if (!self.currentData.workflowProcessTemplate.canEdit) return Promise.resolve(true);

                let changes = self.getAuditChanges(self.originalData, self.currentData);

                if (_.isEmpty(args) && _.isEmpty(changes)) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true);
                }

                if (changes.length > 0) {
                    if(!_.isArray(args)){
                        self.currentData.workflowTaskIDs = _.map(_.sortBy(self.selectedWorkflowTasks, (o) => { return o.sequence; }), (w) => w.workflowTaskID);
                    }
              
                    let apiPromise = self.$api.WorkflowProcessTemplatesApi.save(self.currentData, changes);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {                               
                            self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                            self.currentData = new WorkflowProcessTemplateDataDto(_.cloneDeep(result));
                            self.originalData = new WorkflowProcessTemplateDataDto(_.cloneDeep(result));
                            self.workflowTasks = _.map(_.clone(result.workflowTasks), i => new WorkflowTaskShortDto(i)) || [];
                            if (self.currentData.workflowProcessTemplate.openOrderCount > 0) {
                                if (!self.isOnlyChangeProcessTemplate(changes)) {
                                    let onYes = () => { self.applyToExisting();};
                                    let onNo = () => { GlobalEventManager.saveCompleted({ success: true });};
                                    self.$dialog.confirm("Confirm"
                                        ,`Do you want to apply the changes to the ${self.generateConfirmationMessage(changes)} to the ${self.currentData.workflowProcessTemplate.openOrderCount} existing file(s) using this process template?`
                                        , onYes
                                        , onNo
                                        , { cancelTitle: "No", okTitle: "Yes"}
                                    );
                                }
                            }
                            GlobalEventManager.saveCompleted({ success: true });
                            return Promise.resolve(true);
                        })
                        .catch(error => {
                            if (error?.message?.includes('Workflow Process Template Name')) { 
                                self.$toast.error('Process Template Name already exists');
                            }
                            else {
                                self.$toast.error(`Error Saving ${self.itemTypeName}`);
                            }
                            GlobalEventManager.saveCompleted({ success: false });
                            return Promise.reject(false);
                        });
                } else {
                    let userInitiated = _.get(args, "userInitiated", false);
                    if (userInitiated) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success: true });
                    return Promise.resolve(true) ;
                }
            },

            generateConfirmationMessage(changes) {
                let message = '';
                let itemsChanged = [];
                _.each(changes, (change) => {
                    if (_.includes(change.name, 'workflowTaskIDs')) {
                        if(!_.includes(itemsChanged, 'Workflow')) {
                            itemsChanged.push('Workflow');
                        }
                    } else if (_.includes(change.name, 'documentTemplateIDs')) {
                        if(!_.includes(itemsChanged, 'Documents')) {
                            itemsChanged.push('Documents');
                        }
                    } else if (_.includes(change.name, 'documentPackageIDs')) {
                        if(!_.includes(itemsChanged, 'Packages')) {
                            itemsChanged.push('Packages');
                        }
                    }
                });

                _.each(itemsChanged, (item, idx) => {
                    if (idx === 0) message += item;
                    if (idx > 0 && idx < itemsChanged.length - 1) message += ', ' + item;
                    if (idx > 0 && idx === itemsChanged.length - 1) message += ' and ' + item;
                });

                return message;
            },

            isOnlyChangeProcessTemplate(changes) {
                return !_.some(changes , change => _.includes(change.name, 'workflowTaskIDs') ||
                                                  _.includes(change.name, 'documentTemplateIDs') ||
                                                  _.includes(change.name, 'documentPackageIDs'));
            }
        }
    }
</script>
