<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :title="title"
        title-size="sm"
        :automation_id="automation_id"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="items"
        :export-file-name="exportFileName"
        @import="onImportSelected"
        hide-show-column-chooser
        integrated-search
        hide-clear-filters
    />
</template>

<script>
    import { ImportType as IMPORT_TYPE } from '../../enums';
    import { RateContentDto, EndorsementContentDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import ImportUpdateForm  from "./ImportUpdateForm.vue";

    export default {
        name:"ImportManager",
        props: {
            automation_id: { type: String, default: "tbl_import", required: true },
            importType: { type: Number, default: null, required: true },
            title: { type: String, default: null }
        },
        data () {
            return {
                items: []
            };
        },
        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            selectionActions() {
                return [{
                    name: "import",
                    text: "Import",
                    eventName: "import",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: e => _.every(e.data, (item, index, arr) => index === 0 || item.state === arr[index-1].state) ? false : "Selections must have the same State value"
                }];
            },
            exportFileName() { return this.importType === IMPORT_TYPE.Rates ? "rate_import_items" : "endorsement_import_items"; },
            importTypeName() { return this.importType === IMPORT_TYPE.Rates ? "rate" : "endorsement"; },
            importTypeNamePlural() { return `${this.importTypeName}s`; },
        },
        created(){
            this.initGridConfig();
        },
        methods: {

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    keyExpr: "universalID",
                    columns: self.importType === IMPORT_TYPE.Rate
                        ? [
                            { dataField: "code", dataType: "string" },
                            { dataField: "reportCode", dataType: "string" },
                            { dataField: "extendedDescription", dataType: "string" },
                            { dataField: "state", dataType: "string" },
                            { dataField: "exists", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate }
                        ] : [
                            { dataField: "code", dataType: "string" },
                            { dataField: "description", dataType: "string" },
                            { dataField: "state", dataType: "string" },
                            {
                                dataField: "owner",
                                dataType: "number",
                                format: { type: "currency", precision: 2 }
                            },
                            {
                                dataField: "loan",
                                dataType: "number",
                                format: { type: "currency", precision: 2 }
                            }
                        ],
                    noDataText: `Click the "LOAD IMPORT FILE" button above to import ${self.importTypeNamePlural}.`
                };
            },

            onImportSelected(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                self.$dialog.open({
                    title: `Import Selected ${self.importTypeNamePlural}`,
                    width: 600,
                    adaptive: true,
                    component: ImportUpdateForm,
                    okTitle: "Import",
                    props: {
                        importType: self.importType,
                        selectedItems: items,
                        usState: items[0].state,
                    },
                    onOk: e => {
                        return e.component.save()
                            .then(isValid => {
                                self.$emit('queued', {
                                    success: isValid,
                                    message: `The selected ${_.toLower(IMPORT_TYPE.displayValue(self.importType))} have been successfully scheduled for import.`
                                        + ` Depending on the size of the import, it may be a few minutes before they are available on the Rate Configuration screen.`
                                });
                                if(!isValid) return false;
                                self.removeItems(items);
                                self.gridInstance.deselectAll();
                                return true;
                            });
                    }
                });
            },

            removeItems(items) {
                const self = this;
                if(!_.isArray(items) || _.isEmpty(items)) return;
                _.forEach(items, item => {
                    let itemIndex = _.findIndex(self.items, i => i.universalID === item.universalID);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.refreshGrid();
            },

            loadXml(xml) {
                const self = this;
                self.items =[];
                let uploadEndpoint = self.importType === IMPORT_TYPE.Rates
                    ? "uploadRateContent"
                    : "uploadEndorsementContent";
                let apiPromise = self.$api.ImportUpdateApi[uploadEndpoint](xml);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if (result.length > 0) {
                            self.items = _.map(result, item => self.importType === IMPORT_TYPE.Rates
                                ? new RateContentDto(item)
                                : new EndorsementContentDto(item));
                            self.refreshGrid();
                            self.$emit("xml-loaded");
                            self.$toast.success("Upload complete, please select what you would like to import.");
                        } else {
                            let errMsg = result.message ? result.message : "A problem occurred while uploading.";
                            self.$toast.error({ message: errMsg, autoHideEnabled: false });
                        }
                    })
                    .catch(err => {
                        let errMsg = err.errorMessage ? err.errorMessage : "A problem occurred while uploading.";
                        self.$toast.error({ message: errMsg, autoHideEnabled: false });
                        console.error(err);
                        return err;
                    });
            },

            refreshGrid() {
                _.invoke(this, "gridInstance.refresh");
            }
        }
    };
</script>