<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6">
                <rq-page-section title="Access Levels" headerSize="sm" borderless v-show="categoryVisible('Access Levels')">
                     <div class="row" v-show="fieldVisible('fileInformation')">
                        <div class="col-5">File Information</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_fileEntry"
                                name="fileEntry"
                                automation_id="au_fileEntry"
                                v-model="securityData.userGroupScreenAccess.orderEntry"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isOrderEntryReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('subdivisions')">
                        <div class="col-5">Subdivisions</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_subdivision"
                                name="subdivision"
                                automation_id="au_subdivision"
                                v-model="securityData.userGroupScreenAccess.subdivision"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isSubdivisionsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('surveys')">
                        <div class="col-5">Surveys</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_survey"
                                name="survey"
                                automation_id="au_survey"
                                v-model="securityData.userGroupScreenAccess.survey"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isSurveysReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('fileNotes')">
                        <div class="col-5">File Notes</div>
                        <div class="col">
                                <rq-radio-group
                                id="rdo_notes"
                                name="notes"
                                automation_id="au_notes"
                                v-model="securityData.userGroupScreenAccess.notes"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isNotesReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('workflowDashboard')">
                        <div class="col-5">Workflow Dashboard</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_workflowDashboard"
                                name="workflowDashboard"
                                automation_id="au_workflowDashboard"
                                v-model="securityData.userGroupScreenAccess.workflowDashboard"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isWorkflowDashboardReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('fileWorkflow')">
                        <div class="col-5">File Workflow</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_fileWorkflows"
                                name="fileWorkflows"
                                automation_id="au_fileWorkflows"
                                v-model="securityData.userGroupScreenAccess.updateWorkflow"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isUpdateWorkflowReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('titleProduction')">
                        <div class="col-5">Title Production</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_titleProduction"
                                name="titleProduction"
                                automation_id="au_titleProduction"
                                v-model="securityData.userGroupScreenAccess.commitment"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isTitleProductionReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('policy')">
                        <div class="col-5">Policy</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_policy"
                                name="policy"
                                automation_id="au_policy"
                                v-model="securityData.userGroupScreenAccess.policy"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isPolicyReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('premiumsEndorsements')">
                        <div class="col-5">Premiums & Endorsements</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_premiums"
                                name="premiums"
                                automation_id="au_premiums"
                                v-model="securityData.userGroupScreenAccess.premiums"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isPremiumsEndorsementsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('invoices')">
                        <div class="col-5">Invoices</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_invoices"
                                name="invoices"
                                automation_id="au_invoices"
                                v-model="securityData.userGroupScreenAccess.invoices"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isInvoicesReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('genericSettlementStatements')">
                        <div class="col-5">Settlement Statements</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_genericSettlementStatements"
                                name="genericSettlementStatements"
                                automation_id="au_genericSettlementStatements"
                                v-model="securityData.userGroupScreenAccess.genericSettlementStatement"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isSettlementStatementsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('commercialSettlementStatements')">
                        <div class="col-5">Commercial Settlement Statements</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_commercialSettlementStatements"
                                name="commercialSettlementStatements"
                                automation_id="au_commercialSettlementStatements"
                                v-model="securityData.userGroupScreenAccess.commercialSettlementStatement"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isSettlementStatementsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('payoff')">
                        <div class="col-5">Payoffs</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_loanPayoff"
                                name="loanPayoff"
                                automation_id="au_loanPayoff"
                                v-model="securityData.userGroupScreenAccess.payoff"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isPayoffsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('settlementFees')">
                        <div class="col-5">Settlement Fees</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_endorsementsFees"
                                name="endorsementsFees"
                                automation_id="au_endorsementsFees"
                                v-model="securityData.userGroupScreenAccess.rateRules"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isSettlementFeesReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('checkWriting')">
                        <div class="col-5">Check Writing</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_checkWriting"
                                name="checkWriting"
                                automation_id="au_checkWriting"
                                v-model="securityData.userGroupScreenAccess.checkWriting"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isCheckWritingReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('depositSlips')">
                        <div class="col-5">Deposit Slips</div>
                        <div class="col">
                                <rq-radio-group
                                id="rdo_depositSlip"
                                name="depositSlip"
                                automation_id="au_depositSlip"
                                v-model="securityData.userGroupScreenAccess.depositSlip"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isDepositSlipReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('investmentAccount')">
                        <div class="col-5">Investment Accounts</div>
                        <div class="col">
                                <rq-radio-group
                                id="rdo_investmentAccountIndividual"
                                name="investmentAccountIndividual"
                                automation_id="au_investmentAccountIndividual"
                                v-model="securityData.userGroupScreenAccess.investmentAccountIndividual"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isInvestmentAccountIndividualReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('customData')">
                        <div class="col-5">Custom Data</div>
                        <div class="col">
                            <rq-radio-group
                                id="rdo_customData"
                                name="customData"
                                automation_id="au_customData"
                                v-model="securityData.userGroupScreenAccess.additionalFields"
                                :options="accessOptions"
                                buttons
                                button-variant="utility"
                                size="sm"
                                :disabled="isCustomDataReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-12 col-md-12 col-lg-6">
                <rq-page-section title="Authorized Sections" headerSize="sm" borderless v-show="categoryVisible('Authorized Sections')">
                    <div class="row" v-show="fieldVisible('reporting')">
                        <div class="col">Reporting</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_appReports"
                                v-model="securityData.userGroupApplicationAccess.appReports"
                                :disabled="isAppReportsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('fileScanPlus')">
                        <div class="col">Document Management</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_fileScanPlus"
                                v-model="securityData.userGroupApplicationAccess.appFileScan"
                                :disabled="isFileScanPlusReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('generateDocuments')">
                        <div class="col">Generate Documents</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_documents"
                                v-model="generateDocumentsValue"
                                :disabled="isGenerateDocumentsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('Accounting')">
                        <div class="col">Accounting</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_escrowAccounting"
                                v-model="securityData.userGroupApplicationAccess.appEscrowAccounting"
                                :disabled="isAppEscrowAccountingReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('positivePay')">
                        <div class="col">Positive Pay</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_positivePay"
                                v-model="securityData.userGroupApplicationAccess.appPositivePay"
                                :disabled="isAppPositivePayReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('ebankRecon')">
                        <div class="col">eBank Recon</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_ebankRecon"
                                v-model="securityData.userGroupApplicationAccess.appEBankRecon"
                                :disabled="isAppEBankReconReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('1099Filing')">
                        <div class="col">1099 Filing</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_1099Filing"
                                v-model="securityData.userGroupApplicationAccess.app1099"
                                :disabled="isApp1099ReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('financialApprovals')">
                        <div class="col">Financial Approval</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_financialApprovals"
                                v-model="securityData.userGroupApplicationAccess.appFinancialApprovals"
                                :disabled="isAppFinancialApprovalsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('portfolioManager')">
                        <div class="col">Portfolio Manager</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_portfolioManager"
                                v-model="securityData.userGroupApplicationAccess.appPortfolioManager"
                            />
                        </div>
                    </div>
                    <div v-if="auditLogEnabled" class="row" v-show="fieldVisible('systemAuditLog')">
                        <div class="col">System Audit Log</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_systemAuditLog"
                                :disabled="isAppSystemAuditLogReadOnly"
                                v-model="securityData.userGroupApplicationAccess.appSystemAuditLog"
                            />
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section title="Authorized Configuration Sections" headerSize="sm" borderless v-show="categoryVisible('Authorized Configuration Sections')">
                    <div class="row"  v-show="fieldVisible('documentCreation')">
                        <div class="col">Document Creation</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_appWordDocHelper"
                                v-model="securityData.userGroupApplicationAccess.appWordDocHelper"
                                :disabled="isDocumentCreationReadOnly"
                            />
                        </div>
                    </div>
                    <div v-if="reportCreationEnabled" class="row" v-show="fieldVisible('reportCreation')">
                        <div class="col">Report Creation</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_reportCreation"
                                v-model="securityData.userGroupSecurity.canCreateReports"
                                :disabled="isReportCreationReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('clauses')">
                        <div class="col">Clauses</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_standardLanguage"
                                v-model="securityData.userGroupApplicationAccess.appStandardLanguage"
                                :disabled="isClausesReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('processTemplateSetup')">
                        <div class="col">Process Template Set Up</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_processTemplateSetup"
                                v-model="processTemplateSetupValue"
                                :disabled="isProcessTemplateSetupReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('premiums')">
                        <div class="col">Premiums</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_premiums"
                                v-model="securityData.userGroupSecurity.allowAddEditRates"
                                :disabled="isPremiumsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('endorsementsSettlementFees')">
                        <div class="col">Endorsements/Settlement Fees</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_endorsementsSettlementFees"
                                v-model="securityData.userGroupSecurity.allowEditEndorsementSettlementFees"
                                :disabled="isEndorsementsSettlementFeesReadOnly"
                            />
                        </div>
                    </div>
                    <!-- Needs converted to full/none/readonly instead of switch. -->
                    <div class="row" v-show="fieldVisible('recordingFees')">
                        <div class="col">Recording Fees</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_recordingFees"
                                v-model="securityData.userGroupSecurity.allowAddEditRecordingFees"
                                :disabled="isRecordingFeesReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('transferTaxes')">
                        <div class="col">Transfer Taxes</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_transferTaxes"
                                v-model="securityData.userGroupSecurity.allowAddEditTransferTaxes"
                                :disabled="isTransferTaxesReadOnly"
                            />
                        </div>
                    </div>
                      <div class="row" v-show="fieldVisible('descriptionManagement')">
                        <div class="col">Description Management</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_descriptionManagement"
                                v-model="securityData.userGroupSecurity.fileScanAllowDescriptionManagement"
                                :disabled="isDescriptionManagementReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('categoryManagement')">
                        <div class="col">Category Management</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_categoryManagement"
                                v-model="securityData.userGroupSecurity.fileScanAllowCategoryManagement"
                                :disabled="isCategoryManagementReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import { computed } from "vue";
    import { useLicenseStore } from "@/store/modules/license";
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { DefaultUserSecurity } from "@config/enums";

    export default {
        name: "AccessSection",
        mixins: [SecurityRoleSectionMixin],
        data() {
            return {
                sectionFields: [
                    { label: "Policy", name: "policy", category: "Access Levels" },
                    { label: "Survey", name: "surveys", category: "Access Levels" },
                    { label: "Check Writing", name: "checkWriting", category: "Access Levels" },
                    { label: "Investment Accounts", name: "investmentAccount", category: "Access Levels" },
                    { label: "Premiums & Endorsements", name: "premiumsEndorsements", category: "Access Levels" },
                    { label: "Title Production", name: "titleProduction", category: "Access Levels" },
                    { label: "Invoices", name: "invoices", category: "Access Levels" },

                    { label: "Custom Data", name: "customData", category: "Access Levels" },
                    { label: "Payoffs", name: "payoff", category: "Access Levels" },
                    { label: "Deposit Slips", name: "depositSlips", category: "Access Levels" },
                    { label: "FileNotes", name: "fileNotes", category: "Access Levels" },
                    { label: "Settlement Statements", name: "genericSettlementStatements", category: "Access Levels" },
                    { label: "Commercial Settlement Statements", name: "commercialSettlementStatements", category: "Access Levels" },
                    { label: "File Information", name: "fileInformation", category: "Access Levels" },
                    { label: "Subdivisions", name: "subdivisions", category: "Access Levels" },
                    { label: "Settlement Fees", name: "settlementFees", category: "Access Levels" },
                    { label: "Workflow Dashboard", name: "workflowDashboard", category: "Access Levels" },
                    { label: "File Workflow", name: "fileWorkflow", category: "Access Levels" },

                    { label: "eBank Recon", name: "ebankRecon", category: "Authorized Sections" },
                    { label: "1099 Filing", name: "1099Filing", category: "Authorized Sections" },
                    { label: "Reporting", name: "reporting", category: "Authorized Sections" },
                    { label: "Accounting", name: "Accounting", category: "Authorized Sections" },
                    { label: "Document Management Plus!", name: "fileScanPlus", category: "Authorized Sections" },
                    { label: "Positive Pay", name: "positivePay", category: "Authorized Sections" },
                    { label: "Generate Documents", name: "generateDocuments", category: "Authorized Sections" },
                    { label: "Financial Approval", name: "financialApprovals", category: "Authorized Sections" },
                    { label: "Portfolio Manager", name: "portfolioManager", category: "Authorized Sections" },
                    { label: "System Audit Log", name: "systemAuditLog", category: "Authorized Sections" },

                    { label: "Document Creation", name: "documentCreation", category: "Authorized Configuration Sections" },
                    { label: "Report Creation", name: "reportCreation", category: "Authorized Configuration Sections" },
                    { label: "Clauses", name: "clauses", category: "Authorized Configuration Sections" },
                    { label: "Process Template Setup", name: "processTemplateSetup", category: "Authorized Configuration Sections" },
                    { label: "Premiums", name: "premiums", category: "Authorized Configuration Sections" },
                    { label: "Endorsements/Settlement Fees", name: "endorsementsSettlementFees", category: "Authorized Configuration Sections" },
                    { label: "Recording Fees", name: "recordingFees", category: "Authorized Configuration Sections" },
                    { label: "Transfer Taxes", name: "transferTaxes", category: "Authorized Configuration Sections" },
                    { label: "Description Management", name: "descriptionManagement", category: "Authorized Configuration Sections" },
                    { label: "Category Management", name: "categoryManagement", category: "Authorized Configuration Sections" },
                ]
            };
        },
        watch:{
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue) {
                this.setDefaultValues();
            }
        },
        setup () {
            const licenseStore = useLicenseStore();
            const reportCreationEnabled = computed(() => licenseStore?.features?.reportsCreator);
            const auditLogEnabled = computed(() => licenseStore?.features?.auditlog);
            return{
                reportCreationEnabled,
                auditLogEnabled
            };
        },
        computed: {
            accessOptions() { return UserScreenAccessLevel.getLookupItems("value", "text"); },
            generateDocumentsValue: {
                get() { return this.getScreenAccessSwitchValue("documents"); },
                set(val) { this.setScreenAccessSwitchValue("documents", val); }
            },
            processTemplateSetupValue: {
                get() { return this.getScreenAccessSwitchValue("processTemplateSetup"); },
                set(val) { this.setScreenAccessSwitchValue("processTemplateSetup", val); }
            },
            isAppEBankReconReadOnly(){ return (this.isSysAdmin || this.isEscrowAdmin) && !this.isAdmin;},
            isApp1099ReadOnly(){ return this.isSysAdmin },
            isAppFinancialApprovalsReadOnly(){ return (this.isSysAdmin || this.isEscrowAdmin) && !this.isAdmin;},
            isAppPositivePayReadOnly(){ return (this.isSysAdmin || this.isEscrowAdmin) && !this.isAdmin;},
            isAppEscrowAccountingReadOnly(){ return (this.isSysAdmin || this.isEscrowAdmin) && !this.isAdmin;},
            isGenerateDocumentsReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin; },
            isAppReportsReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin; },
            isInvestmentAccountIndividualReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin; },
            isCustomDataReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isDepositSlipReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin;},
            isCheckWritingReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin; },
            isWorkflowDashboardReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin; },
            isOrderEntryReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin; },
            isNotesReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin;},
            isUpdateWorkflowReadOnly(){ return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin; },
            isSubdivisionsReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isSurveysReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isTitleProductionReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isPolicyReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isPremiumsEndorsementsReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isInvoicesReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isSettlementStatementsReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isPayoffsReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isSettlementFeesReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isRecordingFeesReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isPremiumsReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isEndorsementsSettlementFeesReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isProcessTemplateSetupReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isReportCreationReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isDocumentCreationReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isTransferTaxesReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isClausesReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isFileScanPlusReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isDescriptionManagementReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isCategoryManagementReadOnly(){return this.isSysAdmin || this.isAdmin;},
            isAppSystemAuditLogReadOnly() { return this.isSysAdmin || this.isAdmin; }
        },
        methods: {

            setDefaultValues(){
                const self = this;
                if(self.isEscrowAdmin) {
                    // ACCESS LEVELS : DefaultUserSecurity = { NoAccess: 0, ReadOnly: 1, FullAccess: 2}
                    self.securityData.userGroupScreenAccess.orderEntry = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.subdivision = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.survey = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.notes = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.workflowDashboard = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.updateWorkflow = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.commitment = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.policy = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.premiums = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.invoices = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.documents = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.rateRules = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.payoff = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.genericSettlementStatement = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.commercialSettlementStatement = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.checkWriting = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.depositSlip = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.investmentAccountIndividual = DefaultUserSecurity.FullAccess;
                    self.securityData.userGroupScreenAccess.additionalFields = UserScreenAccessLevel.Full;
                    // AUTHORIZED SECTIONS
                    self.securityData.userGroupApplicationAccess.appReports = true;
                    self.securityData.userGroupApplicationAccess.appFileScan = true;
                    self.securityData.userGroupApplicationAccess.appEscrowAccounting = true;
                    self.securityData.userGroupApplicationAccess.appPositivePay = true;
                    self.securityData.userGroupApplicationAccess.appEBankRecon = true;
                    self.securityData.userGroupApplicationAccess.appFinancialApprovals = true;
                    self.securityData.userGroupApplicationAccess.app1099 = false;
                    self.securityData.userGroupApplicationAccess.systemAuditLog = true;
                    // AUTHORIZED CONFIGURATION SECTIONS
                    self.securityData.userGroupApplicationAccess.appStandardLanguage = false;
                    self.securityData.userGroupApplicationAccess.appWordDocHelper = false;
                    self.securityData.userGroupSecurity.canCreateReports = false;
                    self.securityData.userGroupScreenAccess.processTemplateSetup = UserScreenAccessLevel.None;
                    self.securityData.userGroupSecurity.allowAddEditRates = false;
                    self.securityData.userGroupSecurity.allowEditEndorsementSettlementFees = false;
                    self.securityData.userGroupSecurity.allowAddEditRecordingFees = false;
                    self.securityData.userGroupSecurity.allowAddEditTransferTaxes = false;
                    self.securityData.userGroupSecurity.fileScanAllowDescriptionManagement = false;
                    self.securityData.userGroupSecurity.fileScanAllowCategoryManagement = false;
                }

                if(self.isSysAdmin){
                    self.securityData.userGroupScreenAccess.orderEntry = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.subdivision = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.survey = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.notes = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.workflowDashboard = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.updateWorkflow = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.commitment = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.policy = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.premiums = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.invoices = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.genericSettlementStatement = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.commercialSettlementStatement = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.payoff = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.rateRules = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.checkWriting = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.depositSlip = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.investmentAccountIndividual = UserScreenAccessLevel.Full;
                    self.securityData.userGroupScreenAccess.additionalFields = UserScreenAccessLevel.Full;
                    self.securityData.userGroupApplicationAccess.appReports = true;
                    self.securityData.userGroupApplicationAccess.appFileScan = true;
                    self.securityData.userGroupScreenAccess.generateDocumentsValue = UserScreenAccessLevel.Full;
                    self.securityData.userGroupApplicationAccess.appEscrowAccounting = true;
                    self.securityData.userGroupApplicationAccess.appPositivePay = true;
                    self.securityData.userGroupApplicationAccess.appEBankRecon = true;
                    self.securityData.userGroupApplicationAccess.appFinancialApprovals = true;
                    self.securityData.userGroupApplicationAccess.app1099 = true;
                    self.securityData.userGroupApplicationAccess.appWordDocHelper = true;
                    self.securityData.userGroupSecurity.canCreateReports = true;
                    self.securityData.userGroupApplicationAccess.appStandardLanguage = true;
                    self.securityData.userGroupScreenAccess.processTemplateSetup = UserScreenAccessLevel.Full;
                    self.securityData.userGroupSecurity.allowAddEditRates = true;
                    self.securityData.userGroupSecurity.allowEditEndorsementSettlementFees = true;
                    self.securityData.userGroupSecurity.allowAddEditRecordingFees = true;
                    self.securityData.userGroupSecurity.allowAddEditTransferTaxes = true;
                    self.securityData.userGroupSecurity.fileScanAllowDescriptionManagement = true;
                    self.securityData.userGroupSecurity.fileScanAllowCategoryManagement = true;
                }

                if(self.isAdmin){
                    // AUTHORIZED SECTIONS
                    self.securityData.userGroupApplicationAccess.appStandardLanguage=true;
                    self.securityData.userGroupApplicationAccess.appReports = true;
                    self.securityData.userGroupApplicationAccess.appFileScan = true;
                    self.securityData.userGroupApplicationAccess.appEscrowAccounting = true;
                    self.securityData.userGroupApplicationAccess.appPositivePay = true;
                    self.securityData.userGroupApplicationAccess.appEBankRecon = true;
                    self.securityData.userGroupApplicationAccess.appFinancialApprovals = true;
                    self.securityData.userGroupApplicationAccess.appPortfolioManager = true;
                    self.securityData.userGroupApplicationAccess.app1099 = true;
                    self.securityData.userGroupApplicationAccess.appSystemAuditLog = true;

                    //AUTHORIZED CONFIGURATION SECTIONS
                    self.securityData.userGroupApplicationAccess.appWordDocHelper=true;
                    self.securityData.userGroupSecurity.canCreateReports=true;
                    self.processTemplateSetupValue=true;
                    self.securityData.userGroupSecurity.allowAddEditRates=true;
                    self.securityData.userGroupSecurity.allowEditEndorsementSettlementFees=true;
                    self.securityData.userGroupSecurity.allowAddEditRecordingFees=true;
                    self.securityData.userGroupSecurity.allowAddEditTransferTaxes=true;
                    self.securityData.userGroupSecurity.fileScanAllowDescriptionManagement = true;
                    self.securityData.userGroupSecurity.fileScanAllowCategoryManagement = true;

                }
            },
        }
    }
</script>