<template>
    <rq-section-card v-show="sectionVisible" :title="label" collapsible>
        <div class="row">
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Check Writing" headerSize="sm" borderless v-show="categoryVisible('Check Writing')">
                    <div class="row" v-show="fieldVisible('changeEscrowAccountForAFile')">
                        <div class="col">Change Escrow Account for a file</div>
                        <div class="col">
                            <rqSwitch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changeEscrowAccountForAFile"
                                v-model="securityData.userGroupSecurity.canChangeEscrowAcct"
                                :disabled="isChangeEscrowAccountForAFileReadOnly"
                                v-rq-tooltip.hover.top :title="changeEscrowAccountTooltip"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('enterEditEscrowMemo')">
                        <div class="col">Enter/Edit Escrow Memo</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_enterEditEscrowMemo"
                                v-model="securityData.userGroupSecurity.canEnterEditEscrowMemo"
                                :disabled="isEnterEditEscrowMemoReadOnly"
                            />
                        </div>
                    </div>
                    <!-- [RQO-10448] this will be implemented later
                    <div class="row" v-show="fieldVisible('attemptToRecreateReceiptsDisbursement')">
                        <div class="col">Attempt to Recreate Receipt & Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_attemptToRecreateReceiptsDisbursement"
                                v-model="securityData.userGroupSecurity.allowRecreateReceiptsAndDisbursements"
                            />
                        </div>
                    </div>
                    -->
                    <div class="row" v-show="fieldVisible('escrowLockOptions')">
                        <div class="col-7 drpdwnColumn form-group">
                            <label for="drp_canLockEscrow">ESCROW LOCK OPTIONS</label>
                            <rqSelectBox
                                id="drp_canLockEscrow"
                                :items="escrowLockOptions"
                                automation_id="au_escrowLockOptions"
                                v-model="securityData.userGroupSecurity.escrowLockOption"
                                :disabled="isEscrowLockOptionReadOnly"
                            >
                            </rqSelectBox>
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section  title="Receipts" headerSize="sm" borderless v-show="categoryVisible('Receipts')">
                    <div class="row" v-show="fieldVisible('addDeposits')">
                        <div class="col">Add Receipt</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_addDeposits"
                                v-model="securityData.userGroupSecurity.canAddDeposits"
                                :disabled="isAddDepositsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('depositDelete')">
                        <div class="col">Delete Receipt</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_depositDelete"
                                v-model="securityData.userGroupSecurity.allowDepositDelete"
                                :disabled="isDeleteDepositsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('deleteBookedDeposits')">
                        <div class="col">Delete Booked Receipts</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_deleteBookedDeposits"
                                v-model="securityData.userGroupSecurity.canDeleteBookedDeposits"
                                :disabled="isDeleteBookedDepositsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('receiptOfWires')">
                        <div class="col">Receipt of Wires</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_receiptOfWires"
                                v-model="securityData.userGroupSecurity.allowWires"
                                :disabled="isWiresReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('depositSlipMaintenance')">
                        <div class="col">Deposit Slip Maintenance</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_depositSlipMaintenance"
                                v-model="securityData.userGroupSecurity.allowDepositSlipMaintenance"
                                :disabled="isDepositSlipMaintenanceReadOnly"
                            />
                        </div>
                    </div>
                    <!-- <div class="row" v-show="fieldVisible('transferSlipMaintenance')">
                        <div class="col">Transfer Slip Maintenance</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_transferSlipMaintenance"
                                v-model="securityData.userGroupSecurity.allowTransferSlipMaintenance"
                                :disabled="isTransferSlipMaintenanceReadOnly"
                            />
                        </div>
                    </div> -->
                    <div class="row" v-show="fieldVisible('adjustmentTypeOfFunds')">
                        <div class="col">Adjustment Type of Funds</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_adjustmentTypeOfFunds"
                                v-model="securityData.userGroupSecurity.includeAdjustmentTypeFunds"
                                :disabled="isAdjustmentTypeFundsReadOnly"
                            />
                        </div>
                    </div>
                    <!-- <div class="row" v-show="fieldVisible('depositAdjustment')">
                        <div class="col">Receipt Adjustment</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_depositAdjustment"
                                v-model="securityData.userGroupSecurity.allowDepositAdjustment"
                                :disabled="isDepositAdjustmentReadOnly"
                            />
                        </div>
                    </div> -->
                    <div class="row" v-show="fieldVisible('editDepositOption')">
                        <div class="col-7 drpdwnColumn form-group">
                            <label for="drp_depositChangeOption">EDIT RECEIPT OPTION</label>
                            <rqSelectBox
                                id="drp_depositChangeOption"
                                :items="depositEditingOptions"
                                automation_id="au_editDepositOption"
                                v-model="securityData.userGroupSecurity.depositChangeOption"
                                :disabled="this.isSysAdmin">
                            </rqSelectBox>
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('anticipatedDeposits')">
                        <div class="col-7 drpdwnColumn form-group">
                            <label for="drp_anticipatedDeposits">ANTICIPATED RECEIPTS</label>
                            <rqSelectBox
                                id="drp_anticipatedDeposits"
                                :items="displayAnticipatedDeposits"
                                automation_id="au_anticipatedDeposits"
                                v-model="securityData.userGroupSecurity.anticipatedDeposits">
                                :disabled="this.isSysAdmin"
                            </rqSelectBox>
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('depositPreDateDays') || fieldVisible('depositPostDateDays')">
                        <div class="col-3 form-group">
                            <label for="depositPreDateDays">Pre-Date</label>
                            <div class="input-group">
                                <rqInputNumber
                                id="txt_depositPreDateDays"
                                automation_id="au_depositPreDateDays"
                                defaultValue="0"
                                decimals="0"
                                v-model="securityData.userGroupSecurity.depositPreDateDays"
                                :disabled="isPreDateDaysReadOnly"
                                />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                        <div class="col-3 form-group">
                            <label for="depositPostDateDays">Post-Date</label>
                            <div class="input-group">
                                <rqInputNumber
                                id="txt_depositPostDateDays"
                                automation_id="au_depositPostDateDays"
                                defaultValue="0"
                                decimals="0"
                                v-model="securityData.userGroupSecurity.depositPostDateDays"
                                :disabled="isPostDateDaysReadOnly"
                                />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                    </div>
                </rq-page-section>
                <rq-page-section  title="Investment Accounts" headerSize="sm" borderless v-show="categoryVisible('Investment Accounts')">
                    <div class="row" v-show="fieldVisible('checkDepositSecurityAppliesToInvestmentAccount')">
                        <div class="col">Receipts/Disbursements Security Applies to Investment Accounts</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_checkDepositSecurityAppliesToInvestmentAccount"
                                v-model="securityData.userGroupSecurity.checkDepositSecurityAppliesToInvestmentAccount"
                                :disabled="isCheckDepositSecurityAppliesToInvestmentAccountReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('addChecksDeposits')">
                        <div class="col">Add Receipts/Disbursements</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_addChecksDeposits"
                                v-model="securityData.userGroupSecurity.allowAddInvestmentAccount"
                                :disabled="isAddCheckDepositsReadOnly || securityData.userGroupSecurity.checkDepositSecurityAppliesToInvestmentAccount"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('deleteCheckDeposits')">
                        <div class="col">Delete Receipts/Disbursements</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_deleteCheckDeposits"
                                v-model="securityData.userGroupSecurity.allowDeleteInvestmentAccount"
                                :disabled="isDeleteCheckDepositsReadOnly ||securityData.userGroupSecurity.checkDepositSecurityAppliesToInvestmentAccount"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('transferToInvestmentAccount')">
                        <div class="col">Transfers to Investment Accounts</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_transferToInvestmentAccount"
                                v-model="securityData.userGroupSecurity.allowTransferToInvestmentAccount"
                                :disabled="isTransferToInvestmentAccountReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('transferFromInvestmentAccount')">
                        <div class="col">Transfers from Investment Accounts</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_transferFromInvestmentAccount"
                                v-model="securityData.userGroupSecurity.allowTransferFromInvestmentAccount"
                                :disabled="isTransferFromInvestmentAccountReadOnly"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
            <div class="col-2 col-sm-6">
                <rq-page-section  title="Disbursements" headerSize="sm" borderless v-show="categoryVisible('Disbursements')">
                    <div class="row" v-show="fieldVisible('addChecks')">
                        <div class="col">Add Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_addChecks"
                                v-model="securityData.userGroupSecurity.canAddChecks"
                                :disabled="isAddCheckReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('deleteCheck')">
                        <div class="col">Delete Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_deleteCheck"
                                v-model="securityData.userGroupSecurity.allowDeleteCheck"
                                :disabled="isDeleteCheckReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('printChecks')">
                        <div class="col">Print Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_printChecks"
                                v-model="securityData.userGroupSecurity.allowPrintChecks"
                                :disabled="isPrintCheckReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('securityPinRequired')">
                        <div class="col">Security PIN Required</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_securityPinRequired"
                                :modelValue="!securityData.userGroupSecurity.allowPrintChecks ? false : securityData.userGroupSecurity.securityPinRequired"
                                v-model="securityData.userGroupSecurity.securityPinRequired"
                                :disabled="!securityData.userGroupSecurity.allowPrintChecks"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('stopPayCheck')">
                        <div class="col">Stop Pay Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_stopCheck"
                                v-model="securityData.userGroupSecurity.allowCheckStop"
                                :disabled="isStopPayCheckReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('voidCheck')">
                        <div class="col">Void Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_voidCheck"
                                v-model="securityData.userGroupSecurity.allowCheckVoid"
                                :disabled="isVoidCheckReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('undoCheck')">
                        <div class="col">Undo Disbursement</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_undoCheck"
                                v-model="securityData.userGroupSecurity.allowUndoCheck"
                                :disabled="isUndoCheckReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowChecksWhenUnbalanced')">
                        <div class="col">Print Disbursements when file not in balance</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowChecksWhenUnbalanced"
                                v-model="securityData.userGroupSecurity.allowChecksWhenUnbalanced"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('changePayee')">
                        <div class="col">Change Payee</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_changePayee"
                                v-model="securityData.userGroupSecurity.allowChangePayee"
                                :disabled="isChangePayeeReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowCheckWritingEscrowOutOfRegion')">
                        <div class="col">Allow Check Writing when bank is outside User's region</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowCheckWritingEscrowOutOfRegion"
                                v-model="securityData.userGroupSecurity.allowCheckWritingEscrowOutOfRegion"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('transferOfFunds')">
                        <div class="col">Transfer of Funds</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_transferOfFunds"
                                v-model="securityData.userGroupSecurity.allowFileFundTransfer"
                                :disabled="isFileFundTransferReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('fixSyncAlarmProblems')">
                        <div class="col">Fix Sync Alarm Problems</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_fixSyncAlarmProblems"
                                v-model="securityData.userGroupSecurity.allowFixSynchProblems"
                                :disabled="isFixSyncProblemsReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('simulateCheckPrinting')">
                        <div class="col">Simulate Disbursement Printing</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_simulateCheckPrinting"
                                v-model="securityData.userGroupSecurity.allowCheckPrintOnPaper"
                                :disabled="isCheckPrintOnPaperReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('voidTransfers')">
                        <div class="col">Void Transfers</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_voidTransfers"
                                v-model="securityData.userGroupSecurity.allowVoidTransfers"
                                :disabled="isVoidTransfersReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('forceUserToEnterFullNameAndAddressOnCheck')">
                        <div class="col">Require full name and address on Disbursements</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_forceUserToEnterFullNameAndAddressOnCheck"
                                v-model="securityData.userGroupSecurity.forcePayeeOnChecks"
                            />
                        </div>
                    </div>

                    <!-- <div class="row" v-show="fieldVisible('useExpectedWireOutdate')">
                        <div class="col">Use expected Wire Out date</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_useExpectedWireOutdate"
                                v-model="securityData.userGroupSecurity.useExpectedWireOutDate"
                            />
                        </div>
                    </div> -->
                    <div class="row" v-show="fieldVisible('disburseWithoutAvailableFunds')">
                        <div class="col">Disburse without Available Funds</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_disburseWithoutGoodFunds"
                                v-model="securityData.userGroupSecurity.allowDisbursementWithoutGoodFunds"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('sendChecksToFileScan')">
                        <div class="col">Send Check Copies to Document Management</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_sendChecksToFileScan"
                                v-model="securityData.userGroupSecurity.sendChecksToFileScan"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowConsolidatedDisbursementAdjustment')">
                        <div class="col">Consolidated Disbursement Adjustment</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowConsolidatedDisbursementAdjustment"
                                v-model="securityData.userGroupSecurity.allowConsolidatedDisbursementAdjustment"
                                :disabled="isAllowConsolidatedDisbursementAdjustmentReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowCreateAdvance')">
                        <div class="col">Create Advance</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowCreateAdvance"
                                v-model="securityData.userGroupSecurity.allowCreateAdvance"
                                :disabled="isAllowCreateAdvanceReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('allowCreateLoss')">
                        <div class="col">Create Loss</div>
                        <div class="col">
                            <rq-switch type="stop-light"
                                :on="switchOn"
                                :off="switchOff"
                                size="md"
                                automation_id="au_allowCreateLoss"
                                v-model="securityData.userGroupSecurity.allowCreateLoss"
                                :disabled="isAllowCreateLossReadOnly"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('checkPreDateDays') || fieldVisible('checkPostDateDays')">
                        <div class="col-3 form-group">
                            <label for="checkPreDateDays">Pre-Date</label>
                            <div class="input-group">
                                <rq-input-number id="txt_checkPreDateDays"
                                automation_id="au_checkPreDateDays"
                                defaultValue="0"
                                decimals="0"
                                v-model="securityData.userGroupSecurity.checkPreDateDays"
                                :disabled="isPreDateDaysReadOnly"
                                />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                        <div class="col-3 form-group">
                            <label for="checkPostDateDays">Post-Date</label>
                            <div class="input-group">
                                <rq-input-number
                                id="txt_checkPostDateDays"
                                automation_id="au_checkPostDateDays"
                                defaultValue="0"
                                decimals="0"
                                v-model="securityData.userGroupSecurity.checkPostDateDays"
                                :disabled="isPostDateDaysReadOnly"
                                />
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('voidCheckDays')">
                         <div class="col-3 form-group">
                            <label for="voidCheckDays">VOID DISBURSEMENT DAYS</label>
                            <div class="input-group">
                                <rq-input-number id="txt_voidCheckDays" automation_id="au_voidCheckDays" defaultValue="0" decimals="0"
                                                 minValue="-1" maxValue="1000"
                                                 v-model="securityData.userGroupSecurity.voidCheckDateDays"
                                                 :disabled="isVoidCheckDateDaysReadOnly || !securityData.userGroupSecurity.allowCheckVoid"/>
                                <span class="input-group-text">Days</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('wireOutRequirement') && systemDefaults.showExpectedWireOutDates">
                        <div :class="{ 'col-7 drpdwnColumn form-group': true, }">
                            <label for="drp_wireOutRequirement">WIRE OUT REQUIREMENT</label>
                            <rq-select-box
                                id="drp_wireOutRequirement"
                                :items="wireOutOptions"
                                v-model="securityData.userGroupSecurity.wireOutRequirement"
                                :disabled="!systemDefaults.showExpectedWireOutDates"
                            />
                        </div>
                    </div>
                    <div class="row" v-show="fieldVisible('filescanCategory')">
                        <div :class="{ 'col-7 drpdwnColumn form-group': true, 'form-required': securityData.userGroupSecurity.sendChecksToFileScan, 'has-error': v$.securityData.userGroupSecurity.fileScanCheckCategory.isValid.$invalid }">
                            <label for="drp_fileScanCheckCategory">DOCUMENT MANAGEMENT CATEGORY</label>
                            <rq-select-box
                                id="drp_fileScanCheckCategory"
                                :items="fileScanCategories"
                                v-model="v$.securityData.userGroupSecurity.fileScanCheckCategory.$model"
                            />
                            <rq-validation-feedback
                                :messages="{ 'Document Mgmt Category is Required if Send checks to Document Management is true.': v$.securityData.userGroupSecurity.fileScanCheckCategory.isValid.$invalid }"
                            />
                        </div>
                    </div>
                </rq-page-section>
            </div>
        </div>
    </rq-section-card>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import SecurityRoleSectionMixin from "./SecurityRoleSectionMixin";
    import { EscrowLockOptions, DepositEditingOptions, DisplayAnticipatedDeposits, WireOutOptions } from "@config/enums";
    import { UserGroupSecurityDto }  from "../../models";
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";

    export default {
        name: "CheckWritingSection",
        mixins: [SecurityRoleSectionMixin],
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                sectionFields: 		[
                    { label: "Change Escrow Account for a file", name: "changeEscrowAccountForAFile", category: "Check Writing" },
                    { label: "Enter/Edit Escrow Memo", name: "enterEditEscrowMemo", category: "Check Writing" },
                    //{ label: "Attempt to Recreate Receipt & Disbursement", name: "attemptToRecreateReceiptsDisbursement", category: "Check Writing" },
                    { label: "ESCROW LOCK OPTIONS", name: "escrowLockOptions", category: "Check Writing" },
                    { label: "Add Receipt", name: "addDeposits", category: "Receipts" },
                    { label: "Delete Receipt", name: "depositDelete", category: "Receipts" },
                    { label: "Delete Booked Receipts", name: "deleteBookedDeposits", category: "Receipts" },
                    { label: "Receipt of Wires", name: "receiptOfWires", category: "Receipts" },
                    { label: "Deposit Slip Maintenance", name: "depositSlipMaintenance", category: "Receipts" },
                    //{ label: "Transfer Slip Maintenance", name: "transferSlipMaintenance", category: "Receipts" },
                    { label: "Adjustment Type of Funds", name: "adjustmentTypeOfFunds", category: "Receipts" },
                    //{ label: "Receipt Adjustment", name: "depositAdjustment", category: "Receipts" },
                    { label: "EDIT Receipt OPTION", name: "editDepositOption", category: "Receipts" },
                    { label: "ANTICIPATED Receipts", name: "anticipatedDeposits", category: "Receipts" },
                    { label: "Pre-Date", name: "depositPreDateDays", category: "Receipts", },
                    { label: "Post-Date", name: "depositPostDateDays", category: "Receipts", },
                    { label: "Receipts/Disbursements Security Applies to Investment Accounts", name: "checkDepositSecurityAppliesToInvestmentAccount", category: "Investment Accounts" },
                    { label: "Add Receipts/Disbursements", name: "addChecksDeposits", category: "Investment Accounts" },
                    { label: "Delete Receipts/Disbursements", name: "deleteCheckDeposits", category: "Investment Accounts" },
                    { label: "Transfers to Investment Accounts", name: "transferToInvestmentAccount", category: "Investment Accounts" },
                    { label: "Transfers from Investment Accounts", name: "transferFromInvestmentAccount", category: "Investment Accounts" },
                    { label: "Add Disbursement", name: "addChecks", category: "Disbursements" },
                    { label: "Delete Disbursement", name: "deleteCheck", category: "Disbursements" },
                    { label: "Print Disbursement", name: "printChecks", category: "Disbursements" },
                    { label: "Security PIN Required", name: "securityPinRequired", category: "Disbursements" },
                    { label: "Stop Pay Disbursement", name: "stopPayCheck", category: "Disbursements" },
                    { label: "Void Disbursement", name: "voidCheck", category: "Disbursements" },
                    { label: "Void Disbursement # Days", name: "voidCheckDays", category: "Disbursements" },
                    { label: "Undo Disbursement", name: "undoCheck", category: "Disbursements" },
                    { label: "Print Disbursements when File not in balance", name: "allowChecksWhenUnbalanced", category: "Disbursements" },
                    { label: "Change Payee", name: "changePayee", category: "Disbursements" },
                    { label: "Allow Check Writing when bank is outside User's region", name: "allowCheckWritingEscrowOutOfRegion", category: "Disbursements" },
                    { label: "Transfer of Funds", name: "transferOfFunds", category: "Disbursements" },
                    { label: "Fix Sync Alarm Problems", name: "fixSyncAlarmProblems", category: "Disbursements" },
                    { label: "Simulate Disbursement Printing", name: "simulateCheckPrinting", category: "Disbursements" },
                    { label: "Void Transfers", name: "voidTransfers", category: "Disbursements" },
                    { label: "Require full name and address on Disbursements", name: "forceUserToEnterFullNameAndAddressOnCheck", category: "Disbursements" },
                    { label: "Consolidated Disbursement", name: "allowConsolidatedDisbursementAdjustment", category: "Disbursements" },
                    { label: "Create Advance", name: "allowCreateAdvance", category: "Disbursements" },
                    { label: "Create Loss", name: "allowCreateLoss", category: "Disbursements" },
                    { label: "Use expected Wire Out date", name: "useExpectedWireOutdate", category: "Disbursements" },
                    { label: "Disburse without Available Funds", name: "disburseWithoutAvailableFunds", category: "Disbursements" },
                    { label: "Send Disbursements to Document Management", name: "sendChecksToFileScan", category: "Disbursements" },
                    { label: "Wire Date Requirement", name: "wireOutRequirement", category: "Disbursements" },
                    { label: "Document Management Category", name: "filescanCategory", category: "Disbursements" },
                    { label: "Disbursement Pre-Date", name: "checkPreDateDays", category: "Disbursements" },
                    { label: "Disbursement Post-Date", name: "checkPostDateDays", category: "Disbursements" }
                ],
                originalCheckWritingValues: {}
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                systemDefaults: state => state.system.systemDefaults,
            }),
            escrowLockOptions() { return EscrowLockOptions.lookupItems; },
            depositEditingOptions() { return DepositEditingOptions.lookupItems; },
            displayAnticipatedDeposits() { return DisplayAnticipatedDeposits.lookupItems; },
            fileScanCategories(){ return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES) },
            wireOutOptions() { return WireOutOptions.lookupItems; },
            isChangeEscrowAccountForAFileReadOnly() { return this.systemDefaults.onlySystemAdminsCanChangeEscrowAccounting || this.isSysAdmin || this.isEscrowAdmin; },
            changeEscrowAccountTooltip() { return this.systemDefaults.onlySystemAdminsCanChangeEscrowAccounting && !this.isSysAdmin ? 'Only System Admins can change Escrow Accounts for a File' : ''; },
            isEnterEditEscrowMemoReadOnly() { return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin); },
            isEscrowLockOptionReadOnly() {return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isAddDepositsReadOnly() {return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin;},
            isDeleteDepositsReadOnly() {return this.isSysAdmin || this.isEscrowAdmin || this.isAdmin;},
            isDeleteBookedDepositsReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isWiresReadOnly(){return this.isSysAdmin || this.isAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isDepositSlipMaintenanceReadOnly(){return  this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isTransferSlipMaintenanceReadOnly(){return  this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},

            isAdjustmentTypeFundsReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isDepositAdjustmentReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isPreDateDaysReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isPostDateDaysReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isCheckDepositSecurityAppliesToInvestmentAccountReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isTransferToInvestmentAccountReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isTransferFromInvestmentAccountReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isAddCheckReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isDeleteCheckReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isStopPayCheckReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isVoidCheckReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isPrintCheckReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isUndoCheckReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isChangePayeeReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isFileFundTransferReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isFixSyncProblemsReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isCheckPrintOnPaperReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isVoidTransfersReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isVoidCheckDateDaysReadOnly(){return this.isSysAdmin || this.isAdmin || this.isEscrowAdmin;},
            isAllowConsolidatedDisbursementAdjustmentReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isAllowCreateAdvanceReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isAllowCreateLossReadOnly(){return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isAddCheckDepositsReadOnly() {return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            isDeleteCheckDepositsReadOnly() {return this.isSysAdmin || (this.isEscrowAdmin && !this.isAdmin);},
            checkSwitchOff() { return !this.securityData.userGroupSecurity.allowPrintChecks ? 0 :  this.securityData.userGroupSecurity.securityPinRequired }
        },
        watch: {
            "v$.securityData.userGroupSecurity.fileScanCheckCategory.$error":{
                handler: function(newValue, oldValue) {
                    if (this.securityData.userGroupSecurity.sendChecksToFileScan){
                        this.$events.$emit(`disable-save`, _.isNullOrEmpty(this.securityData.userGroupSecurity.fileScanCheckCategory));
                    }
                    else {
                        this.$events.$emit(`disable-save`, false);
                    }
                },
                immediate: true
            },
            "securityData.userGroupSecurity.sendChecksToFileScan":{
                handler: function(newValue, oldValue) {
                    if (newValue && _.isNullOrEmpty(this.securityData.userGroupSecurity.fileScanCheckCategory)) {
                        this.$events.$emit(`disable-save`, true);
                    }
                    else{
                        this.$events.$emit(`disable-save`, false);
                    }
                },
                immediate: true
            },
            "securityData.userGroupSecurity.allowPrintChecks"(newValue){
                if(!newValue) {
                    this.securityData.userGroupSecurity.securityPinRequired = false;
                }
            },
            isSysAdmin: function(newValue){
                this.setDefaultValues();
            },
            isAdmin: function(newValue){
                this.setDefaultValues();
            },
            isEscrowAdmin: function(newValue){
                this.setDefaultValues();
            }
        },
        validations() {
            const self = this;
            var result = {
                securityData: {
                    userGroupSecurity: {
                        fileScanCheckCategory: {
                            required: requiredIf(() =>
                                self.securityData.userGroupSecurity.sendChecksToFileScan
                            ),
                            isValid(val) {
                                return self.securityData.userGroupSecurity.sendChecksToFileScan ? !_.isNullOrEmpty(self.securityData.userGroupSecurity.fileScanCheckCategory) : true;
                            }
                        },
                    },
                }
            }
            return result;
        },
        methods: {
            setDefaultValues(){
                const self = this;

                // Escrow Admin
                if(self.isEscrowAdmin){
                    self.securityData.userGroupSecurity.canEnterEditEscrowMemo = true ;
                    self.securityData.userGroupSecurity.escrowLockOption = 0 ; // All
                    self.securityData.userGroupSecurity.canAddDeposits = true ;
                    self.securityData.userGroupSecurity.allowDepositDelete = true ;
                    self.securityData.userGroupSecurity.canDeleteBookedDeposits = true ;
                    self.securityData.userGroupSecurity.allowWires = true ;
                    self.securityData.userGroupSecurity.allowDepositSlipMaintenance = true ;
                    self.securityData.userGroupSecurity.allowTransferSlipMaintenance = true ;

                    self.securityData.userGroupSecurity.includeAdjustmentTypeFunds = true ;
                    self.securityData.userGroupSecurity.allowDepositAdjustment = true ;
                    self.securityData.userGroupSecurity.depositChangeOption = DepositEditingOptions.ChangeUnverifiedUnreconciled ;
                    self.securityData.userGroupSecurity.anticipatedDeposits = 1 ; // Full
                    self.securityData.userGroupSecurity.depositPreDateDays = -1 ;
                    self.securityData.userGroupSecurity.depositPostDateDays = -1 ;
                    self.securityData.userGroupSecurity.checkDepositSecurityAppliesToInvestmentAccount = true ;
                    self.securityData.userGroupSecurity.allowAddInvestmentAccount = true ;
                    self.securityData.userGroupSecurity.allowDeleteInvestmentAccount = true ;
                    self.securityData.userGroupSecurity.allowTransferToInvestmentAccount = true ;
                    self.securityData.userGroupSecurity.allowTransferFromInvestmentAccount = true ;
                    self.securityData.userGroupSecurity.canAddChecks = true ;
                    self.securityData.userGroupSecurity.allowDeleteCheck = true ;
                    self.securityData.userGroupSecurity.allowPrintChecks = true ;
                    self.securityData.userGroupSecurity.allowCheckStop = true ;
                    self.securityData.userGroupSecurity.allowCheckVoid = true ;
                    self.securityData.userGroupSecurity.voidCheckDateDays = -1;
                    self.securityData.userGroupSecurity.allowUndoCheck = true ;
                    self.securityData.userGroupSecurity.allowChecksWhenUnbalanced = true ;
                    self.securityData.userGroupSecurity.allowChangePayee = true ;
                    self.securityData.userGroupSecurity.allowCheckWritingEscrowOutOfRegion = false ;
                    self.securityData.userGroupSecurity.allowFileFundTransfer = true ;
                    self.securityData.userGroupSecurity.allowFixSynchProblems = true ;
                    self.securityData.userGroupSecurity.allowCheckPrintOnPaper = true ;
                    self.securityData.userGroupSecurity.allowVoidTransfers = true ;
                    self.securityData.userGroupSecurity.forcePayeeOnChecks = false ;
                    self.securityData.userGroupSecurity.allowDisbursementWithoutGoodFunds = true ;
                    self.securityData.userGroupSecurity.sendChecksToFileScan = false ;
                    self.securityData.userGroupSecurity.checkPreDateDays = -1 ;
                    self.securityData.userGroupSecurity.checkPostDateDays = -1 ;
                    self.securityData.userGroupSecurity.allowConsolidatedDisbursementAdjustment = true;
                    self.securityData.userGroupSecurity.allowCreateAdvance = true;
                    self.securityData.userGroupSecurity.allowCreateLoss = true;
                    self.v$.securityData.userGroupSecurity.fileScanCheckCategory.$model = 0; // No
                    self.securityData.userGroupSecurity.wireOutRequirement = WireOutOptions.CanUseEitherDate;
                }

                // Admin
                if(self.isAdmin) {
                    // Check writing
                    self.securityData.userGroupSecurity.canEnterEditEscrowMemo = true;
                    self.securityData.userGroupSecurity.escrowLockOption = EscrowLockOptions.All;

                    // Deposits
                    self.securityData.userGroupSecurity.canAddDeposits = true;
                    self.securityData.userGroupSecurity.allowDepositDelete = true;
                    self.securityData.userGroupSecurity.canDeleteBookedDeposits = false;
                    self.securityData.userGroupSecurity.allowWires = true;
                    self.securityData.userGroupSecurity.allowDepositSlipMaintenance = true;
                    self.securityData.userGroupSecurity.allowTransferSlipMaintenance = true ;

                    self.securityData.userGroupSecurity.includeAdjustmentTypeFunds = true;
                    self.securityData.userGroupSecurity.allowDepositAdjustment = true;
                    self.securityData.userGroupSecurity.depositChangeOption = DepositEditingOptions.ChangeUnverifiedUnreconciled;
                    self.securityData.userGroupSecurity.anticipatedDeposits = DisplayAnticipatedDeposits.Full;
                    self.securityData.userGroupSecurity.depositPreDateDays = -1;
                    self.securityData.userGroupSecurity.depositPostDateDays = -1;

                    // Investment Accounts
                    self.securityData.userGroupSecurity.checkDepositSecurityAppliesToInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowAddInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowDeleteInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowTransferToInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowTransferFromInvestmentAccount = true;

                    // Checks
                    self.securityData.userGroupSecurity.canAddChecks = true;
                    self.securityData.userGroupSecurity.allowDeleteCheck = true;
                    self.securityData.userGroupSecurity.allowPrintChecks = true;
                    self.securityData.userGroupSecurity.allowCheckStop = true;
                    self.securityData.userGroupSecurity.allowCheckVoid = true;
                    self.securityData.userGroupSecurity.allowUndoCheck = true;
                    self.securityData.userGroupSecurity.allowChecksWhenUnbalanced = false;
                    self.securityData.userGroupSecurity.allowChangePayee = true;
                    self.securityData.userGroupSecurity.allowCheckWritingEscrowOutOfRegion = false;
                    self.securityData.userGroupSecurity.allowFileFundTransfer = true;
                    self.securityData.userGroupSecurity.allowFixSynchProblems = true;
                    self.securityData.userGroupSecurity.allowCheckPrintOnPaper = true;
                    self.securityData.userGroupSecurity.allowVoidTransfers = true;
                    self.securityData.userGroupSecurity.forcePayeeOnChecks = false;
                    self.securityData.userGroupSecurity.allowDisbursementWithoutGoodFunds = true;
                    self.securityData.userGroupSecurity.useExpectedWireOutDate = false;
                    self.securityData.userGroupSecurity.sendChecksToFileScan = false;
                    self.securityData.userGroupSecurity.fileScanCheckCategory = null;
                    self.securityData.userGroupSecurity.checkPreDateDays = -1;
                    self.securityData.userGroupSecurity.checkPostDateDays = -1;
                    self.securityData.userGroupSecurity.voidCheckDateDays = -1;
                    self.securityData.userGroupSecurity.allowConsolidatedDisbursementAdjustment = true;
                    self.securityData.userGroupSecurity.allowCreateAdvance = true;
                    self.securityData.userGroupSecurity.allowCreateLoss = true;
                    self.securityData.userGroupSecurity.wireOutRequirement = WireOutOptions.CanUseEitherDate;
                }

                // System Admin
                if(self.isSysAdmin){
                    self.securityData.userGroupSecurity.canEnterEditEscrowMemo = true;
                    self.securityData.userGroupSecurity.allowRecreateReceiptsAndDisbursements = false;
                    self.securityData.userGroupSecurity.escrowLockOption = EscrowLockOptions.All;
                    self.securityData.userGroupSecurity.canAddDeposits = true;
                    self.securityData.userGroupSecurity.allowDepositDelete = true;
                    self.securityData.userGroupSecurity.canDeleteBookedDeposits = true;
                    self.securityData.userGroupSecurity.allowWires = true;
                    self.securityData.userGroupSecurity.allowDepositSlipMaintenance = true;
                    self.securityData.userGroupSecurity.allowTransferSlipMaintenance = true ;

                    self.securityData.userGroupSecurity.includeAdjustmentTypeFunds = true;
                    self.securityData.userGroupSecurity.allowDepositAdjustment = true;
                    self.securityData.userGroupSecurity.depositChangeOption = DepositEditingOptions.ChangeUnverifiedUnreconciled;
                    self.securityData.userGroupSecurity.anticipatedDeposits = DisplayAnticipatedDeposits.Full;
                    self.securityData.userGroupSecurity.depositPreDateDays = -1;
                    self.securityData.userGroupSecurity.depositPostDateDays = -1;
                    self.securityData.userGroupSecurity.checkDepositSecurityAppliesToInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowAddInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowDeleteInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowTransferToInvestmentAccount = true;
                    self.securityData.userGroupSecurity.allowTransferFromInvestmentAccount = true;
                    self.securityData.userGroupSecurity.canAddChecks = true;
                    self.securityData.userGroupSecurity.allowDeleteCheck = true;
                    self.securityData.userGroupSecurity.allowPrintChecks = true;
                    self.securityData.userGroupSecurity.allowCheckStop = true;
                    self.securityData.userGroupSecurity.allowCheckVoid = true;
                    self.securityData.userGroupSecurity.voidCheckDateDays = -1;
                    self.securityData.userGroupSecurity.allowUndoCheck = true;
                    self.securityData.userGroupSecurity.allowChecksWhenUnbalanced = true;
                    self.securityData.userGroupSecurity.allowChangePayee = true
                    self.securityData.userGroupSecurity.allowCheckWritingEscrowOutOfRegion = false;
                    self.securityData.userGroupSecurity.allowFileFundTransfer = true;
                    self.securityData.userGroupSecurity.allowFixSynchProblems = true;
                    self.securityData.userGroupSecurity.allowCheckPrintOnPaper = true;
                    self.securityData.userGroupSecurity.allowVoidTransfers = true;
                    self.securityData.userGroupSecurity.forcePayeeOnChecks = false;
                    self.securityData.userGroupSecurity.allowDisbursementWithoutGoodFunds = true;
                    self.securityData.userGroupSecurity.sendChecksToFileScan = false;
                    self.securityData.userGroupSecurity.allowConsolidatedDisbursementAdjustment = true;
                    self.securityData.userGroupSecurity.allowCreateAdvance = true;
                    self.securityData.userGroupSecurity.allowCreateLoss = true;
                    self.securityData.userGroupSecurity.fileScanCheckCategory = null;
                    self.securityData.userGroupSecurity.checkPreDateDays = -1;
                    self.securityData.userGroupSecurity.checkPostDateDays = -1;
                    self.securityData.userGroupSecurity.wireOutRequirement = WireOutOptions.CanUseEitherDate;
                }

                // Special circumstance.
                self.securityData.userGroupSecurity.canChangeEscrowAcct = self.systemDefaults.onlySystemAdminsCanChangeEscrowAccounting
                    ? self.isSysAdmin
                    : self.isSysAdmin || self.isEscrowAdmin;
            }
        }
    }
</script>
