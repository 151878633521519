<template>    
     <div class="content-wrapper">        
        <rqdx-action-data-grid 
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions" 
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            target-inactive-column="isInactive"
            :strikethrough-if-true="['isInactive']"
            @delete="onDeleteItem"
            @activate="onActivateItem($event, 'Activate')"
            @inactivate="onActivateItem($event, 'Inactivate')"
            show-include-inactive
            focus-after-insert="first-row"  
            hide-show-column-chooser
            integrated-search
            :rq-editable="!readOnly"
            rq-filters
        />
    </div>
</template>

<script>
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { mapState, mapGetters } from "vuex";
    import { PayoffDescriptionDto }  from "../models";
    export default {
        name:"PayoffDescriptionList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: ""
            };
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();           
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            gridInstance() {
                return _.get(this, "$refs.dataGrid.gridInstance", null);
            },
        },
        watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });                
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                const self = this;
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = _.get(this.$route.meta, "itemKey");
                this.selectionActions = [
                    { 
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) { return self.readOnly;}
                    },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true,
                        disabled: function(e) {
                                return !_.every(e.data, ['isInactive', true]); }
                        },
                    { name: "inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true,
                        disabled: function(e) {
                            return !_.every(e.data, ['isInactive', false]); }
                    }
                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { 
                            dataField: self.itemKey, 
                            visible: false,
                            allowEditing: false,
                            showInColumnChooser: false,
                        },                        
                        {
                            dataField: "description",
                            dataType: "string",                            
                            sortOrder: "asc",                            
                            editorOptions: 
                            { 
                                maxLength: 40,
                                valueChangeEvent: "input"
                            }, 
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Description already exists"
                                } 
                            ]
                        },
                        {
                            caption: "Inactive",
                            dataField: "isInactive",
                            dataType: "boolean",
                            allowEditing: false,
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    // onRowUpdating(e) {
                    //     e.cancel = new Promise((resolve, reject) => {
                    //         self.$dialog.confirm(
                    //             "Confirm Edit", 
                    //             `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`, 
                    //             () => resolve(false), //continue with edit
                    //             () => resolve(true), //cancel edit
                    //             { cancelTitle: 'No', okTitle: 'Yes'});  
                    //     });
                    // }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            fetchData () {
                const self = this;
                let apiPromise = self.$api.PayoffDescriptionsApi.getPayoffDescriptions();
                return self.$rqBusy.wait(apiPromise)
                        .then(result => { 
                            self.items = _.map(result, i => new PayoffDescriptionDto(i));
                            self.refresh();
                        }).catch(error => {
                            self.items = [];
                            self.$toast.error({ message: `Error Loading ${self.itemTypeNamePlural}.` });
                            return error;
                        });
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => { 
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1); 
                });

                return dup ? false : true;
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1 
                    ? self.itemTypeNamePlural 
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete", 
                    `Are you sure you want to delete the selected ${itemLabel}?`, 
                    okHandler, 
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            }, 

            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.PayoffDescriptionsApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            onActivateItem(e, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                    
                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            refresh() {
                const self = this;
                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },
            delete(keys) {            
                const self = this;    
                let apiPromise = self.$api.PayoffDescriptionsApi.deletePayoffDescriptions(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.removeItems(keys);
                        let message = keys.length > 1 
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` 
                            : `${self.itemTypeName} was deleted.`
                        self.$toast.success(message); 
                        return true;
                    })
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {                            
                            self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {                            
                            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                        }
                        console.error(error);
                        return error;       
                    });
            },
            save(item, changes){
                const self = this;
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.PayoffDescriptionsApi.savePayoffDescription(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                        return result;
                    })
                    .catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new PayoffDescriptionDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new PayoffDescriptionDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.payoffDescriptionID === key);                
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new PayoffDescriptionDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            },
            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.gridInstance.refresh();
            }
        }
    }
</script>
