<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            integrated-search
            rq-filters
            hide-clear-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DuplicateSearchDto }  from "../models";
    import DuplicateSearchForm  from "./DuplicateSearchForm.vue";
    import {DuplicateSearchField}  from "../models";
    export default {
        name:"DuplicateSearchList",
        props: { },
        data () {
            return {
                items: [],
                newItems: []
            };
        },
        created(){
            this.initGridConfig();
            this.$events.on(this.addEventName, this.onAddItem);
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            addEventName() { return `add:${this.elementName()}`; },
            regions() { return this.lookupHelpers.getRegions(); },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            },
        },
        methods: {

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            dataType: "number",
                            name: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                loadMode: "raw",
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            }
                        },
                        { dataField: "description", dataType: "string" },
                        { dataField: "numberOfRequiredFields", dataType: "number", caption: "Number of Required Fields" }
                    ]
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.DuplicateSearchApi.getDuplicateSearchSetups();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new DuplicateSearchDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAddItem() {
                this.loadItem(new DuplicateSearchDto({regionID: this.user.regionID}));
            },

            onEditItem(e) {
                this.loadItem(new DuplicateSearchDto(e.data));
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = _.map(items, item => self.$api.DuplicateSearchApi.deleteSearch(item[self.itemKey]));
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success(message);
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: "No", okTitle: "Yes"});
            },

            loadItem(item) {
                const self = this;
                let dialogId = null;
                let refreshOnExit = false;
                self.newItems = [];
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(success => {
                            if(!success) return false;
                            if (addAnother) {
                                refreshOnExit = true;
                                self.newItems.push(e.component.item);
                                self.$dialog.reloadComponent({ dialogId, props: { item: new DuplicateSearchDto({regionID: self.user.regionID}) } });
                                return false;
                            }
                            self.refresh();
                            return success;
                        });
                };

                let onCancel = e => {
                    if(!refreshOnExit) return true;
                    self.refresh();
                }

                dialogId = self.$dialog.open({
                    title: `${item.isNew ? "Add": "Edit"} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.description}`)}`,
                    width: 835,
                    adaptive: true,
                    scrollable: false,
                    component: DuplicateSearchForm,
                    props: { item,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field) },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                let inItems = _.some(self.items, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1)
                    && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1))
                let inNewItems = _.some(self.newItems, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1))
                let isDuplicate = inItems || inNewItems;

                return isDuplicate;
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); }
        }
    }
</script>
