<template>
    <div class="content-wrapper">
            <rq-banner
                variant="error"
                title="Please correct the highlighted errors on screen to continue."
                icon="fas fa-exclamation-triangle"
                :visible="v$.$error"
            />
        <rq-page-section borderless>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group form-required" :class="{ 'has-error': v$.item.type.$error }">
                    <label for="drp_do_not_do_business_with_type">Type</label>
                    <dx-select-box
                        :input-attr="{ id:'drp_do_not_do_business_with_type', automation_id:'drp_do_not_do_business_with_type' }"
                        :items="doNotDoBusinessWithTypes"
                        value-expr="id"
                        display-expr="name"
                        v-model="item.type"
                        @valueChanged="typeChanged"
                    />
                    <rq-validation-feedback>Type is required.</rq-validation-feedback>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group form-required" :class="{ 'has-error': v$.item.regionIDs.$error }">
                        <label for="region">Region</label>
                        <dx-tag-box
                            :element-attr="{ automation_id: 'drp_do_not_do_business_with_region' }"
                            class="form-control"
                            :data-source="regions"
                            display-expr="displayName"
                            value-expr="regionID"
                            :search-enabled="true"
                            :show-selection-controls="true"
                            :show-clear-button="true"
                            :max-displayed-tags="3"
                            :show-drop-down-button="true"
                            :multiline="false"
                            v-model:value="item.regionIDs"
                            apply-value-mode="useButtons"
                            no-data-text="No regions found."
                            @valueChanged="regionChanged"
                            >
                        </dx-tag-box>
                        <rq-validation-feedback
                            message="At least one region is required."
                        />
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                    <label for="drp_do_not_do_business_with_branches">Branches</label>
                    <dx-tag-box
                        :element-attr="{ automation_id: 'drp_do_not_do_business_with_branches' }"
                        class="form-control"
                        :data-source="branchDataSource"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="3"
                        :show-drop-down-button="true"
                        :multiline="false"
                        item-template="branch-item"
                        v-model:value="item.branchIDs"
                        apply-value-mode="useButtons"
                        no-data-text="No branches found.">
                        <template #branch-item="{ data }">
                            <div class="d-inline-flex align-items-baseline">
                                <span>{{data.name}}</span>
                                <span class="text-muted font-italic font-xs font-weight-light ms-auto">{{data.regionDisplay}}</span>
                            </div>
                        </template>
                    </dx-tag-box>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 form-group" :class="{ 'form-required': nameRequired,  'has-error': v$.item.name.$error }">
                    <label class="form-control-label" for="txt_do_not_do_business_with_name">Name</label>
                    <input automation_id="txt_do_not_do_business_with_name"
                        class="form-control"
                        type="text"
                        v-model="item.name"
                    />
                    <rq-validation-feedback>Name is required.</rq-validation-feedback>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 form-group" :class="{ 'form-required': addressRequired,  'has-error': v$.item.address1.$error }">
                    <label class="form-control-label" for="txt_do_not_do_business_with_address1">Address 1</label>
                    <input automation_id="txt_do_not_do_business_with_address1"
                        class="form-control"
                        type="text"
                        v-model="item.address1"
                    />
                    <rq-validation-feedback>Address 1 is required.</rq-validation-feedback>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 form-group" >
                    <label class="form-control-label" for="txt_do_not_do_business_with_address2">Address 2</label>
                    <input automation_id="txt_do_not_do_business_with_address2"
                        class="form-control"
                        type="text"
                        v-model="item.address2"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 form-group" :class="{ 'form-required': addressRequired,  'has-error': v$.item.city.$error }">
                    <label class="form-control-label" for="txt_do_not_do_business_with_city">City</label>
                    <input automation_id="txt_do_not_do_business_with_city"
                        class="form-control"
                        type="text"
                        v-model="item.city"
                    />
                    <rq-validation-feedback>City is required.</rq-validation-feedback>
                </div>
                <div class="col-12 col-sm-12 col-md-2 col-lg-2 form-group" :class="{ 'form-required': addressRequired,  'has-error': v$.item.state.$error }">
                    <label class="form-control-label" for="drp_do_not_do_business_with_state">State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_do_not_do_business_with_state' }"
                        :items="usStatesLookup"
                        value-expr="id"
                        display-expr="id"
                        v-model="item.state"
                    />
                    <rq-validation-feedback>State is required.</rq-validation-feedback>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 form-group" :class="{ 'form-required': addressRequired,  'has-error': v$.item.zip.$error}">
                    <label class="form-control-label" for="txt_do_not_do_business_with_zip">Zip</label>
                    <rq-masked-input
                        ref="zipRef"
                        id="txt_do_not_do_business_with_zip"
                        maskType="zip"
                        grid-field="zip"
                        v-model="item.zip"
                        is-masked
                        always-refresh
                    />
                    <rq-validation-feedback
                            :messages="{
                                'Zip is required': v$.item.zip.required.$invalid,
                                'Zip is invalid.': v$.item.zip.zipFormatValid.$invalid
                            }"
                        />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 form-group" :class="{ 'form-required': entityRequired,  'has-error': v$.item.entity.$error }" >
                            <label class="form-control-label" for="txt_do_not_do_business_with_entity">Entity Name</label>
                            <input automation_id="txt_do_not_do_business_with_entity"
                                class="form-control"
                                type="text"
                                v-model="item.entity"
                            />
                            <rq-validation-feedback>Entity is required.</rq-validation-feedback>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 form-group" :class="{ 'form-required': companyRequired,  'has-error': v$.item.company.$error }">
                            <label class="form-control-label" for="txt_do_not_do_business_with_company">Company Name</label>
                            <input automation_id="txt_do_not_do_business_with_company"
                                class="form-control"
                                type="text"
                                v-model="item.company"
                            />
                            <rq-validation-feedback>Company is required.</rq-validation-feedback>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 form-group form-required" :class="{'has-error': v$.item.source.$error }">
                            <label class="form-control-label" for="txt_do_not_do_business_with_source">Source</label>
                            <input automation_id="txt_do_not_do_business_with_source"
                                class="form-control"
                                type="text"
                                v-model="item.source"
                            />
                            <rq-validation-feedback>Source is required.</rq-validation-feedback>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                            <div class="rq-range-input">
                                <div class="row">
                                    <div class="col form-group mb-0 pe-0 form-required" :class="{ 'has-error': v$.item.effectiveDate.$error }">
                                        <label for="dtp_do_not_do_business_with_effective_date">Effective Date</label>
                                        <rqdx-date-box
                                            id="dtp_do_not_do_business_with_effective_date"
                                            class="form-control"
                                            v-model="item.effectiveDate"
                                            type="date"
                                            display-format="MM/dd/yyyy"
                                            styling-mode="outlined"
                                            default-button-display
                                        />
                                        <rq-validation-feedback>Effective date is required.</rq-validation-feedback>
                                    </div>
                                    <div class="col-auto rq-range-delimiter-with-labels">
                                        To
                                    </div>
                                    <div class="col form-group mb-0 ps-0" >
                                        <label for="dtp_do_not_do_business_with_expiration_date">Expiration Date</label>
                                        <rqdx-date-box
                                            id="dtp_do_not_do_business_with_expiration_date"
                                            v-model="item.expirationDate"
                                            class="form-control"
                                            type="date"
                                            display-format="MM/dd/yyyy"
                                            styling-mode="outlined"
                                            default-button-display
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                    <div
                        :class="{
                            'rq-img-preview':true,
                            'no-preview':!fileExists,
                            'rq-hover': showHoverState
                        }"
                        @mouseover="showHoverState=true"
                        @mouseout="showHoverState=false"
                        @click="showHoverState=false">
    
                        <div class="rq-img-header">
                            <div class="rq-img-actions">
                                <b-btn :automation_id="previewButtonId" :disabled="!fileExists" variant="link" class="btn-theme" size="sm" @click="onPreviewClick">Preview</b-btn>
                                <b-btn :automation_id="loadButtonId" variant="link" class="btn-theme" size="sm" @click="onLoadClick">Load File</b-btn>
                                <b-btn :automation_id="deleteButtonId" variant="link" class="btn-theme" size="sm" @click="onDeleteClick">Remove File</b-btn>
                            </div>
                        </div>
                        <div class="rq-img-body">
                            <form enctype="multipart/form-data">
                                <div class="img-preview container-flat" v-show="fileExists">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3">
                                                File Name:
                                            </div>
                                            <div class="col-9">
                                                {{fileData.fileName}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">
                                                File Size:
                                            </div>
                                            <div class="col-9">
                                                {{(fileData.fileSizeBytes / 1024 / 1024).toFixed(6)}} MB
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">
                                                File Type:
                                            </div>
                                            <div class="col-9">
                                                {{fileData.fileType}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upload-guide" >
                                    <p >Click or drag to<br> upload a file</p>
                                </div>
                                <input
                                    ref="fileInput"
                                    automation_id="do_not_do_business_with_file_input"
                                    id="do_not_do_business_with_file_input"
                                    type="file"
                                    name="DoNotDoBusinesWithFile"
                                    :disabled="isBusy"
                                    @change="onFileChange($event)"
                                    class="input-file"
                                    
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </rq-page-section>
        </div>
    </template>
    <script>
        import { ref, watchEffect } from "vue";
        import { mapGetters } from "vuex";
        import { DoNotDoBusinessWithType } from "@config/enums";
        import { required } from '@vuelidate/validators';
        import { useVuelidate } from "@vuelidate/core";
        import { DoNotDoBusinessWithDto, DoNotDoBusinessWithFileDto } from "../models";
        import { requiredIf } from "@vuelidate/validators";
        import DocumentPreview from "@/shared/components/rq/dialogs/DocumentPreview.vue";
    
        export default {
            props: {
                data: { type: Object, default: () => { return {}; } }
            },
            setup: (props) => { 
    
                const fileInput = ref(null);
                const loadButtonId = ref("");
                const deleteButtonId = ref("");
                const fileInputId = ref("");
                const previewButtonId = ref("");
                watchEffect(() => {
                    let prefix = _.isEmpty(props.id) ? "rq_image_preview" : props.id;
                    loadButtonId.value = `${prefix}_load_image`;
                    deleteButtonId.value = `${prefix}_delete_image`;
                    fileInputId.value = `${prefix}_file_input`;
                    previewButtonId.value = `${prefix}_preview_image`;
                });
                return {
                    v$: useVuelidate(),
                    fileInput,
                    loadButtonId,
                    deleteButtonId,
                    fileInputId,
                    previewButtonId
                }
            },
            data: function() {
                return {
                    originalItem: {},
                    item: {},
                    fileData: new DoNotDoBusinessWithFileDto(),
                    originalFileData: {},
                    showHoverState: false
                }
            },
            validations() {
                const self = this;
                return {
                    item: {
                        type: { required },
                        regionIDs: { required },
                        name:{
                            required: requiredIf(() => self.nameRequired),
                        },
                        entity:{
                            required: requiredIf(() => self.entityRequired),
                        },
                        company:{
                            required: requiredIf(() => self.companyRequired),
                        },
                        address1:{
                            required: requiredIf(() => self.addressRequired),
                        },
                        city:{
                            required: requiredIf(() => self.addressRequired),
                        },
                        state:{
                            required: requiredIf(() => self.addressRequired),
                        },
                        zip:{
                            required: requiredIf(() => self.addressRequired),
                            zipFormatValid: (val) => _.isEmpty(val) || val.length === 5 || val.length === 10,
                        },
                        source: { required },
                        effectiveDate:{ required }
                    }
                };
            },
            computed: {
                ...mapGetters([
                    "lookupHelpers",
                    "lookupItems",
                    "usStatesLookup"
                ]),
                regions() { return this.lookupHelpers.getRegions(); },
                branchDataSource() { 
                    var branches = this.lookupHelpers.getBranches(this.item.regionIDs);
                    _.map(branches, s => s.regionDisplay = _.find(this.regions, r => r.regionID == s.regionID)?.description)
                    branches = _.orderBy(branches, ["regionID"], ["name"]);
                    return branches;
                },
                nameRequired(){ return this.item.type == DoNotDoBusinessWithType.Name; },
                entityRequired(){ return this.item.type == DoNotDoBusinessWithType.Entity; },
                companyRequired(){ return this.item.type == DoNotDoBusinessWithType.Company; },
                addressRequired() { return this.item.type == DoNotDoBusinessWithType.Address; },
                doNotDoBusinessWithTypes() { return DoNotDoBusinessWithType.lookupItems; },
                isBusy() { return this.$rqBusy.isBusy(); },
                fileExists () { return !_.isEmpty(this.fileData) && !_.isEmpty(this.fileData.fileName); },
            },
            created() {
                this.originalItem = new DoNotDoBusinessWithDto(this.data);
                this.item = new DoNotDoBusinessWithDto(this.data);
                let file = (this.data?.files?.length ?? 0) > 0 ? this.data.files[0] : {};
                this.fileData = new DoNotDoBusinessWithFileDto(file)
            },
            methods: {
                typeChanged(e){
                    const self = this;
                    self.v$.$touch();   
                },
                async save(){
                    const self = this;
                    self.v$.$touch();
    
                    if (self.v$.$error) return Promise.resolve(false);
    
                    try
                    {
                        var doNotDoBusinessWith = new DoNotDoBusinessWithDto(self.item);
                        var originalDoNotDoBusinessWith = new DoNotDoBusinessWithDto(self.originalItem);
                        let itemData = doNotDoBusinessWith.toDataObject();
                        let fileData = self.fileData.toDataObject();
                        let changes = self.getAuditChanges(originalDoNotDoBusinessWith.toDataObject(), itemData);
                        let fileUpdated = self.fileData.updated;
                        if (changes.length === 0 && !fileUpdated) return Promise.resolve(true);
    
                        itemData.files = fileData != null && !_.isEmpty(fileData.fileName) ? [fileData] : [];
                        let promise = self.$api.DoNotDoBusinessWithApi.save(itemData);
                        let result = await self.$rqBusy.wait(promise);
    
                        self.$toast.success("Saved Do Not Do Business With Successfully.");
                        return true;
                    }
                    catch(err) {
                            self.$toast.error("Error Saving Do Not Do Business With");
                            console.error(err);
                            return false;
                    }
                },
                regionChanged(e){
                    var branches = this.lookupHelpers.getBranches(e.value);
                    var availableBranchIDs = _.map(branches, b => b.id);
                    this.item.branchIDs = _.filter(this.item.branchIDs, id => _.includes(availableBranchIDs, id));
                },
                 isValid(){
                    this.errorMessage = '';
                    this.v$.$reset();
                    this.v$.$touch();
                    if (this.v$.$error) {
                        this.errorMessage = 'Please correct the highlighted fields.';
                        return false;
                    } else {
                        return true;
                    }
                },
                onFileChange (e) {
                    const self = this;
                    let fileInput = e.target;
    
                    let doNotDoBusinessWithFileID = self.fileData?.doNotDoBusinessWithFileID;
                    let doNotDoBusinessWithID = self.itemData?.doNotDoBusinessWithID;
                    let fileSizeBytes = fileInput.files[0].size; //size in MB
    
                    let formData = new FormData();
                    
                    let file = fileInput.files[0];
    
                    // Capture the file details before calling readAsDataURL
                    let fileName = file.name;
                    let fileType = file.type;
    
                    formData.append(fileInput.name, fileInput.files[0], fileName);
    
                    let reader = new FileReader();
                    reader.onload = function(e2) {
                        self.fileData = new DoNotDoBusinessWithFileDto({
                            doNotDoBusinessWithFileID: doNotDoBusinessWithFileID,
                            doNotDoBusinessWithID: doNotDoBusinessWithID,
                            dataUrl: e2.target.result,
                            fileName: fileName,
                            fileSizeBytes: fileSizeBytes,
                            fileType: fileType,
                            updated: true
                        });
                    };
                    reader.readAsDataURL(fileInput.files[0]);
    
                    e.target.value = "";
                },
                async onPreviewClick() {
                    const self = this;
                    if(self.fileData.dataUrl == null){
                        let promise = self.$api.DoNotDoBusinessWithApi.getDoNotDoBusinessWithFile(self.item.doNotDoBusinessWithID);
                        let result = await self.$rqBusy.wait(promise);
                        self.fileData.dataUrl = result.dataUrl;
                        self.fileData.doNotDoBusinessWithFileID = result.doNotDoBusinessWithFileID;
                        self.fileData.fileName = result.fileName;
                        self.fileData.fileType = result.fileType;
                    }
                    

                    self.$dialog.open({
                    title: "Preview",
                    height: "85%",
                    width: "1050",
                    resizable: false,
                    component: DocumentPreview,
                    props: {
                        content: self.fileData.dataUrl,
                        fileName: self.fileData.fileName,
                        contentType: self.fileData.fileType,
                    }
                });
                },
                onLoadClick() {
                    let fileInput = _.get(this, "$refs.fileInput", null)
                    if(!fileInput) return;
                    fileInput.click();
                },
    
                 onDeleteClick () {
                    const self = this;
                    self.$dialog.confirm("Delete File","Are you sure you want to remove this file?", () => {
                        let fileInput = _.get(this, "$refs.fileInput", null)
                        if(!fileInput) return;
                        fileInput.value = "";
                        self.fileData = new DoNotDoBusinessWithFileDto({updated: true});
                        self.$emit("delete");
                    });
                },
            }
        }
    </script>
    