<template>
    <div class="content-wrapper">        
        <rqdx-action-data-grid 
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions" 
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            @delete="onDeleteItem"
            hide-show-column-chooser
            focus-after-insert="new-row"  
            integrated-search
            :rq-editable="!readOnly"
            hide-clear-filters
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { HudStatusDto }  from "../models";

    export default {
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: ""
            };
        },        
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            gridInstance() {
                return _.get(this, "$refs.dataGrid.gridInstance", null) || {};
            },
        },
        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });                
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                const self = this;
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = _.get(this.$route.meta, "itemKey");
                this.selectionActions = [
                    { 
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) { return self.readOnly;}
                    }
                ];
            },
            initGridConfig(){
                const self = this;                  
                self.gridConfig = {           
                    columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Description",
                            editorOptions: { maxLength: 20 },
                            sortIndex: 0,
                            sortOrder: "asc",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Description already exists"
                                } 
                            ]
                        }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit", 
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`, 
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});  
                        });
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = items.map(item => item.hudStatusID);

                    let apiPromise = self.$api.HudStatusesApi.deleteHudStatuses(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message }); 
                            return true;
                        })
                        .catch(error => {                            
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {                            
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);                                    
                            } else {                            
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });                          
                            }
                            return error;       
                        })
                        .finally(() => {
                            self.fetchData();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});    
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => { 
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1); 
                });

                return dup ? false : true;
            },
            fetchData() {          
                const self = this;              
                let apiPromise = self.$api.HudStatusesApi.getHudStatuses();             
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new HudStatusDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            save(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }

                let apiPromise = self.$api.HudStatusesApi.saveHudStatus(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => { 
                        self.$toast.success(`${self.itemTypeName} ${item.description} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new HudStatusDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new HudStatusDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.hudStatusID === key);                
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new HudStatusDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            }
        }
    }
</script>
