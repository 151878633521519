<template>
    <div class="content-wrapper">        
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            @delete="onDeleteItem"
            @activate="onActivateItem"
            show-include-inactive
            hide-show-column-chooser
            focus-after-insert="new-row"
            integrated-search
            rq-filters
            hide-settings
            target-inactive-column="inactive"
            :strikethrough-if-true="['inactive']"
            :rq-editable="!readOnly">
            </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { TagDto } from "../models";
    import { SystemLookupItemDto } from "@/shared/models/models";
    import { SYSTEM_MUTATIONS } from "@/store/mutations";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: 'TagsList',
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                newTagName: "",
                addEventName: ""
            };
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            gridInstance() {
                return _.get(this.$refs, "dataGrid.gridInstance", null) || {};
            },
        },
        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });                
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                const self = this;
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = _.get(this.$route.meta, "itemKey");
                this.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) { return _.some(e.data, ['canDelete', false]) || self.readOnly;}
                    },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return (_.some(e.data, ['inactive', false]));}},
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return (_.some(e.data, ['inactive', true]));}}
                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "tagName",
                            dataType: "string",
                            caption: "Name",
                            editorOptions: { maxLength: 100 },
                            sortIndex: 0,
                            sortOrder: "asc",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isUniqueItem,
                                    message: "Name already exists"
                                }
                            ]
                        },
                        { dataField: "inactive", dataType: "boolean", allowEditing: false, cellTemplate: DxGridUtils.boolCellTemplate }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing documents. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.TagsApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.fetchData();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.TagsApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.gridInstance.clearSelection();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            isUniqueItem(row){
                const self = this;
                let dup = {};
                if (_.trim(_.get(row.data, "tagName"), "") == '') return true;
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.tagName)) === _.toLower(_.trim(_.get(row.data, "tagName"), ""))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row.data, self.itemKey, -1), -1);
                });
                return _.isEmpty(dup);
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.TagsApi.get();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new TagDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            save(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }

                let apiPromise = self.$api.TagsApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} ${item.tagName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
                this.gridInstance.updateDimensions();
            },
            updateStore() {
                let list = _.map(this.items, t => new SystemLookupItemDto({id: t.tagID, name: t.tagName, regionID: 1, data: null})) || [];
                this.$store.commit(SYSTEM_MUTATIONS.SET_SYSTEM_LOOKUP_DOCUMENT_TAGS, list);
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new TagDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new TagDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
                if(itemIndex < 0) return;

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new TagDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                        self.updateStore();
                    });
            }
        }
    }
</script>
