import { MainLayout } from "@/layouts";
import ConfigurationMain from "./ConfigurationMain.vue";
import Routes from "./routes";
export default {
    path: "/configuration",
    component: MainLayout,
    meta: { include: false },
    children: [
        {
            name: "cfg:default",
            path: "",
            redirect: "/configuration/search",
            component: ConfigurationMain,
            meta: {
                label: "Configuration",
                megaMenuTooltip: "Configuration Links",
                include: true,
                skipSaveOnLeave: true,
                showAddFileButton: true
            },
            children: Routes
        }
    ]
};